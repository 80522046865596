import {IJsonFromField} from 'components/InputFormulario/types';
import {GeoService} from 'services/GeoService';
import {Cidade} from 'models/Cidade';
import {Estado} from 'models/Estado';

export class EnderecoLaboratorio {
    public id?: number
    public endereco?: string
    public numero?: string
    public bairro?: string
    public cidade?: Cidade
    public estado?: Estado
    public estado_id?: number
    public cidade_id?: number
    public cep?: string
    public localizacao?: { latitude: number, longitude: number };

    constructor(defaultData?: EnderecoLaboratorio) {
        this.id = defaultData?.id
        this.endereco = defaultData?.endereco
        this.numero = defaultData?.numero
        this.bairro = defaultData?.bairro
        this.cidade = defaultData?.cidade
        if (this.cidade && this.cidade.id) {
            this.cidade_id = this.cidade.id
        }
        this.estado = defaultData?.estado
        if (this.estado && this.estado.id) {
            this.estado_id = this.estado.id
        }
        this.cep = defaultData?.cep
        this.localizacao = defaultData?.localizacao
    }

    getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'endereco',
                label: 'Endereço',
                type: 'text',
                valueDefault: this.endereco,
                validators: {
                    required: true
                },
                onChange: async (data, setValue) => {
                    if (data !== this.endereco) {
                        this.endereco = data;
                    }
                }
            },
            {
                name: 'numero',
                label: 'Número',
                type: 'text',
                valueDefault: this.numero,
                onChange: async (data, setValue) => {
                    if (data !== this.numero) {
                        this.numero = data;
                    }
                }
            },
            {
                name: 'bairro',
                label: 'Bairro',
                type: 'text',
                valueDefault: this.bairro,
                validators: {
                    required: true
                },
                onChange: async (data, setValue) => {
                    if (data !== this.bairro) {
                        this.bairro = data;
                    }
                }
            },
            {
                name: 'cep',
                label: 'CEP',
                type: 'text',
                validators: {
                    maxLength: 8,
                    minLength: 8,
                    required: true
                },
                valueDefault: this.cep,
                onChange: async (data, setValue) => {
                    if (data !== this.cep) {
                        this.cep = data;
                    }
                }
            },
            {
                name: 'estado_id',
                label: 'Estado',
                type: 'select',
                validators: {
                    required: true
                },
                choices: async () => {
                    return GeoService.obterListaDeEstados().then(e => {
                        return e.map(x => {
                            return {
                                label: x.nome,
                                value: x.id
                            }
                        })
                    })
                },
                onChange: async (data, setValue) => {
                    if (data !== this.estado_id) {
                        this.estado_id = data;
                    }
                },
                valueDefault: {
                    label: this.estado?.nome,
                    value: this.estado?.id
                }
            },
            {
                name: 'cidade_id',
                label: 'Cidade',
                type: 'select',
                validators: {
                    required: true
                },
                valueDefault: {
                    label: this.cidade?.nome,
                    value: this.cidade?.id
                },
                choices: async () => {
                    return this.estado_id ? GeoService.obterListaDeCidadesPorEstado(this.estado_id).then(e => {
                        return e.map(x => {
                            return {
                                label: x.nome,
                                value: x.id
                            }
                        })
                    }) : Promise.resolve([])
                },
                onChange: async (data, setValue) => {
                    if (data !== this.cidade_id) {
                        this.cidade_id = data;
                    }
                }
            }
        ]
    }
}
