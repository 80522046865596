import React, {useEffect, useState} from 'react';
import {Paginacao} from 'util/Paginacao';
import {Pedido, STATUS_PEDIDO} from 'models/Pedido';
import {ApiService} from 'services/ApiService';
import moment from 'moment';
import {ControleDePaginacao} from 'components/ControleDePaginacao';
import Numero from 'components/Numero';
import {useForm} from 'react-hook-form';
import {FieldJsonForm} from 'components/InputFormulario';
import {Loader} from 'components/Loader';
import CircularProgress from '@material-ui/core/CircularProgress';
import {RouteComponentProps} from 'react-router';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import {
    Card,
    CardContent,
    CardHeader, Collapse,
    Grid,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow, Theme,
    Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {ArrowDropDown, ArrowDropUp} from '@material-ui/icons';
import StyledTableRow from 'components/StyledTableRow';


export function PedidosPage({history}: RouteComponentProps) {
    const [showFiltro, setShowFiltro] = React.useState(false);
    const [paramsDefault] = useState({
        status: []
    })
    const [paginacaoPedido, setPaginacaoPedido] = useState<Paginacao<Pedido> | null>()
    const [pedidos, setPedidos] = useState<Pedido[]>();
    const [parms, setParms] = useState<any>(paramsDefault);

    const formMethods = useForm(
        {
            reValidateMode: 'onSubmit',
            defaultValues: paramsDefault
        }
    );
    useEffect(() => {
        if (paginacaoPedido === undefined) {
            ApiService.getAll('/pedidos', parms).then(async data => {
                const paginacao = new Paginacao('/pedidos', Pedido, data, parms)
                setPaginacaoPedido(paginacao);
                setPedidos(await paginacao.getItensProximaPagina() || [])
            }, () => {
                setPaginacaoPedido(null);
                setPedidos([])
            });
        }

    });
    const submitFiltros = formMethods.handleSubmit(data => {
        setParms(data);
        setPedidos(undefined);
        setPaginacaoPedido(undefined);
    });

    return (
        <PageContainer heading="Pedidos" breadcrumbs={[]}>
            <GridContainer justify="center"
                           alignItems="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant={'h3'} component={'div'}
                                        style={{display: 'flex', alignItems: 'center'}}>
                                Filtros <Button style={{
                                marginLeft: 'auto'
                            }} onClick={() => setShowFiltro(x => !x)}>
                                {showFiltro ? <ArrowDropUp/> : <ArrowDropDown/>}
                            </Button>
                            </Typography>
                            <Collapse in={showFiltro}>
                                <GridContainer justify="flex-start"
                                               alignItems="center">
                                    <Grid item md={2}>
                                        <Box>
                                            <FieldJsonForm formMethods={formMethods} field={{
                                                type: 'number',
                                                name: 'pedido',
                                                label: 'Número do pedido'
                                            }}/>
                                        </Box>
                                    </Grid>
                                </GridContainer>
                                <GridContainer justify="flex-start"
                                               alignItems="center">
                                    <Grid item md={6}>
                                        <Box style={{
                                            marginTop: '6px',
                                            width: '100%'
                                        }}>
                                            <FieldJsonForm formMethods={formMethods} field={{
                                                type: 'multi-select',
                                                name: 'status',
                                                label: 'Status do pedido',
                                                valueDefault: paramsDefault.status.map(x => {
                                                    return {
                                                        label: STATUS_PEDIDO[Number(x)].andamento,
                                                        value: x
                                                    }
                                                }),
                                                choices: Object.keys(STATUS_PEDIDO).filter(x => Number(x) !== 0).map(x => {
                                                    return {
                                                        label: STATUS_PEDIDO[Number(x)].andamento,
                                                        value: String(x)
                                                    }
                                                })
                                            }}/>
                                        </Box>
                                    </Grid>
                                </GridContainer>
                                <GridContainer>
                                    <Grid item md={6}>
                                        <FieldJsonForm formMethods={formMethods} field={{
                                            type: 'text',
                                            name: 'paciente',
                                            label: 'Nome do Paciente'
                                        }}/>
                                    </Grid>
                                </GridContainer>
                                <GridContainer>
                                    <Grid item md={6}>
                                        <FieldJsonForm formMethods={formMethods} field={{
                                            type: 'text',
                                            name: 'dentista',
                                            label: 'Nome do Dentista'
                                        }}/>
                                    </Grid>
                                </GridContainer>
                                <GridContainer>
                                    <Grid item md={6}>
                                        <Box component={'p'} style={{
                                            textAlign: 'right'
                                        }}>
                                            <Button variant="contained" color="primary" onClick={submitFiltros}
                                                    disabled={pedidos === undefined}>
                                                {pedidos === undefined &&
                                                <CircularProgress color={'inherit'} size={'1rem'}/>}
                                                {pedidos && 'Pesquisar'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </GridContainer>
                            </Collapse>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader>
                            <Typography variant={'h3'} component={'div'}>
                                Pedidos
                            </Typography>
                        </CardHeader>
                        <CardContent>
                            <Box className="Cmt-table-responsive">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Número</TableCell>
                                            <TableCell>Dentista</TableCell>
                                            <TableCell>Paciente</TableCell>
                                            <TableCell>Valor</TableCell>
                                            <TableCell>Data</TableCell>
                                            <TableCell>Prev. de Entrega</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pedidos && pedidos.map(x => {
                                            return <StyledTableRow hover key={x.id}
                                                                   onClick={() => history.push('pedido/' + x.id)}>
                                                <TableCell>{String(x.id).padStart(8, '0')}</TableCell>
                                                <TableCell>{x.dentista?.nome}</TableCell>
                                                <TableCell>{x.paciente}</TableCell>
                                                <TableCell><Numero numero={x.valor || 0} mascara={'MOEDA'}
                                                                   minimumFractionDigits={2}/></TableCell>
                                                <TableCell>{moment(x.data_cadastro).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>{moment(x.data_cadastro).add('days', x.prazo || 0).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>{x.status_display}</TableCell>
                                            </StyledTableRow>
                                        })}
                                        {pedidos && pedidos.length === 0 && <TableRow>
                                            <TableCell colSpan={7}>
                                                Nenhum pedido
                                            </TableCell>
                                        </TableRow>}
                                        {pedidos === undefined && <TableRow>
                                            <TableCell colSpan={7}>
                                                <Loader/>
                                            </TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                {paginacaoPedido &&
                <ControleDePaginacao totalPaginas={paginacaoPedido.getQuantidadeDePaginas()}
                                     onPageChange={(pg) => {
                                         setPedidos(undefined);
                                         paginacaoPedido.getItensDaPagina(pg).then(us => {
                                             setPedidos(us);
                                         });
                                     }}/>}
            </GridContainer>
        </PageContainer>
    )
        ;
}
