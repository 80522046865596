import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Box, Card, CardContent, FormControlLabel, Modal, Radio, RadioGroup} from '@material-ui/core';
import {Pedido} from 'models/Pedido';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {ApiService} from 'services/ApiService';
import {Chat} from '@material-ui/icons';
import {Formulario} from 'components/Formulario';
import moment from 'moment';
import {LocalData} from 'components/LocalData';
import {ModalCenter} from 'components/ModalCenter';

const useStyles = makeStyles<any>(theme => ({
    root: {
        width: '100%',
        backgroundColor: 'transparent'
    },
    button: {
        marginRight: theme.spacing(2),
    },
    instructions: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

function getSteps() {
    return ['Aceite do pedido', 'Aguardando Material', 'Pedido em Andamento', 'Aguardando Entrega', 'Pedido Finalizado'];
}


const getStepperPedido = (statusPedido: number) => {
    switch (statusPedido) {
        case 1:
            return 0;
        case 2:
            return 1;
        case 3:
            return 2;
        case 7:
            return 3;
        case 8:
            return 5;
    }
}

export default function PedidoStepper(props: { pedido: Pedido }) {
    const classes = useStyles();
    const [pedido, setPedido] = useState(props.pedido)
    const steps = getSteps();
    const [option, setOption] = useState('')
    const [showModalRecusa, setShowModalRecusa] = useState(false);

    const alterarStatusPedido = (status: number, observacao?: string) => {
        ApiService.put('/pedidos', String(pedido?.id), {
            status: status,
            observacao: observacao
        }).then(ped => {
            setPedido(new Pedido(ped));

        }, () => {
        })
    }


    return (
        <>
            {pedido.status != 9 && <Stepper style={{
                backgroundColor: 'transparent',
                width: '100%',
                overflowX: 'scroll'
            }} alternativeLabel activeStep={getStepperPedido(pedido?.status || 1)}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>}
            <Card>
                <CardContent>
                    {pedido.status === 1 && <Box>
                        <Typography variant={'h1'} align={'center'}>
                            Pedido aguardando aprovação!
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align={'center'}>
                            Esse pedido foi recebido e está aguardando sua aprovação!<br/> Verifique
                            todas as informações
                            passadas
                            pelo
                            dentista e clique no botão abaixo para aceitar o pedido.<br/>
                            <b>Você tem
                                até {<LocalData data={moment(pedido.data_cadastro).add(1, 'days').toDate()}/>} para
                                aceitar esse pedido ou ele será recusado automaticamente!</b>
                        </Typography>
                        <br/>
                        <br/>
                        <GridContainer justify={'center'} alignItems={'center'}>
                            <Grid item md={4} xs={12}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">Parar dar andamento no pedido,
                                        selecione uma
                                        opção abaixo</FormLabel>
                                    <br/>
                                    <RadioGroup aria-label="quiz" name="quiz" value={option}
                                                onChange={(event, value) => setOption(value)}>
                                        <FormControlLabel style={{
                                            textAlign: 'center'
                                        }} value="aceitar" control={<Radio/>}
                                                          label={<Typography color={'textPrimary'}>
                                                              ACEITAR PEDIDO E INICIAR PRODUÇÃO
                                                          </Typography>}/>
                                        <FormControlLabel value="aceitarEAguardar" control={<Radio/>}
                                                          label={<Typography color={'textPrimary'}>ACEITAR
                                                              PEDIDO E AGUARDAR ENVIO DE
                                                              MATERIAL</Typography>}/>
                                        <FormControlLabel value="recusar" control={<Radio/>}
                                                          label={<Typography color={'error'}>RECUSAR
                                                              PEDIDO</Typography>}/>
                                    </RadioGroup>
                                    <br/>
                                    <Button type="submit" variant="outlined" color="primary"
                                            disabled={!option}
                                            onClick={() => {
                                                switch (option) {
                                                    case 'aceitar':
                                                        alterarStatusPedido(3)
                                                        break;
                                                    case 'aceitarEAguardar':
                                                        alterarStatusPedido(2);
                                                        break;
                                                    case 'recusar':
                                                        setShowModalRecusa(true);
                                                        break;
                                                }
                                            }}>
                                        Salvar
                                    </Button>
                                </FormControl>
                            </Grid>
                        </GridContainer>
                    </Box>}
                    {pedido && pedido.status === 2 && <Box>
                        <Typography variant={'h1'} align={'center'}>
                            Pedido aguardando material!
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align={'center'}>
                            Esse pedido foi aceito e está aguardando material!<br/> Se você já recebeu
                            todo o material
                            necessário
                            para iniciar a produção clique no botão abaixo para dar inicio ao
                            pedido!
                            <br/>
                            <br/>
                            <Button variant={'contained'} color="secondary"
                                    onClick={() => alterarStatusPedido(3)}>
                                MATERIAL RECEBIDO, INICIAR PRODUÇÃO!
                            </Button>
                        </Typography>
                    </Box>}
                    {pedido && pedido.status === 3 && <Box>
                        <Typography variant={'h1'} align={'center'}>
                            Pedido em produção!
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align={'center'}>
                            Esse pedido foi aceito e está em produção!
                        </Typography>
                        <br/>
                        <br/>
                        <GridContainer justify={'center'} alignItems={'center'}>
                            <Grid item md={4} xs={12}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">Parar dar andamento no pedido,
                                        selecione uma
                                        opção abaixo</FormLabel>
                                    <br/>
                                    <RadioGroup aria-label="quiz" name="quiz" value={option}
                                                onChange={(event, value) => setOption(value)}>
                                        <FormControlLabel style={{
                                            textAlign: 'center'
                                        }} value="finalizar" control={<Radio/>}
                                                          label={<Typography color={'textPrimary'}>
                                                              PEDIDO FINALIZADO E LOGO SERÁ ENTREGUE!
                                                          </Typography>}/>
                                        <FormControlLabel value="finalizarComEntrega" control={<Radio/>}
                                                          label={<Typography color={'textPrimary'}>
                                                              PEDIDO FINALIZADO E ENTREGUE AO DENTISTA!</Typography>}/>
                                    </RadioGroup>
                                    <br/>
                                    <Button type="submit" variant="outlined" color="primary"
                                            disabled={!option}
                                            onClick={() => {
                                                switch (option) {
                                                    case 'finalizar':
                                                        alterarStatusPedido(7)
                                                        break;
                                                    case 'finalizarComEntrega':
                                                        alterarStatusPedido(8);
                                                        break;
                                                }
                                            }}>
                                        Salvar
                                    </Button>
                                </FormControl>
                            </Grid>
                        </GridContainer>
                    </Box>}
                    {pedido && pedido.status === 7 && <Box>
                        <Typography variant={'h1'} align={'center'}>
                            Pedido aguardando entrega!
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align={'center'}>
                            Esse pedido foi aceito e concluído mas está aguardando a entrega.
                            <br/>
                            <br/>
                            <Button variant={'contained'} color="secondary"
                                    onClick={() => alterarStatusPedido(8)}>
                                PEDIDO ENTREGUE AO DENTISTA!
                            </Button>
                        </Typography>
                    </Box>}
                    {pedido && pedido.status === 8 && <Box>
                        <Typography variant={'h1'} align={'center'}>
                            Pedido finalizado!
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align={'center'}>
                            Esse pedido foi concluido!
                        </Typography>
                    </Box>}
                    {pedido && pedido.status === 9 && <Box>
                        <Typography variant={'h1'} align={'center'}>
                            Pedido Cancelado!
                        </Typography>
                        <br/>
                        <br/>
                        <Typography align={'center'}>
                            {pedido.motivo_cancelamento}
                        </Typography>
                    </Box>}
                    <br/>
                    <br/>
                    <Typography align='center'>
                        {pedido.status === 8 || pedido.status === 9 ? <Button startIcon={<Chat/>} color='default'>
                                VER HISTÓRIO DE CONVERSA
                            </Button> :
                            <Button startIcon={<Chat/>} color='default'>
                                FALAR COM DENTISTA
                            </Button>}
                    </Typography>
                </CardContent>
            </Card>
            <ModalCenter open={showModalRecusa} onClose={() => setShowModalRecusa(false)}>
                <h1>Recusar Pedido {String(pedido.id).padStart(8, '0')}</h1>
                <p>Informe abaixo o motivo da recusa do pedido</p>
                <Formulario name={'recusaPedido'} fields={[
                    {
                        type: 'text-area',
                        label: 'Motivo',
                        name: 'observacao',
                        validators: {
                            required: {
                                value: true,
                                message: 'Informe o motivo da recusa do pedido'
                            }
                        }
                    }
                ]} onSubmit={(data) => {
                    if (window.confirm('Deseja realmente recusar esse pedido? Essa alteração não poderá ser revertida!')) {
                        alterarStatusPedido(9, data.observacao);
                        setShowModalRecusa(false);
                    }
                }} textSubmitButton={'Recusar Pedido'}/>
            </ModalCenter>
        </>
    );
}
