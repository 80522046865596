import React, {useEffect, useState} from 'react';
import {
    Tab,
    Tabs,
    Avatar,
    Grid,
    Card,
    CardContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Switch, Theme
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {Laboratorio} from 'models/Laboratorio';
import {AppRootState} from 'redux/reducers';
import {Formulario} from 'components/Formulario';
import {LaboratorioService} from 'services/LaboratorioService';
import {labChange} from 'redux/actions/Session';
import {BtnSelectAndCropImage} from 'components/CropImage';
import {EnderecoLaboratorio} from 'models/EnderecoLaboratorio';
import {Loader} from 'components/Loader';
import {Paginacao} from 'util/Paginacao';
import {Usuario} from 'models/Usuario';
import {UsuarioService} from 'services/UsuarioService';
import {ControleDePaginacao} from 'components/ControleDePaginacao';
import {GeoService} from 'services/GeoService';
import {ConfiguracaoFrete} from 'models/ConfiguracaoFrete';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import Typography from '@material-ui/core/Typography';
import {ModalCenter} from 'components/ModalCenter';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import {Edit, SecurityRounded} from '@material-ui/icons';
import StyledTableRow from 'components/StyledTableRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {createStyles} from '@material-ui/styles';
import {AlertEvent, AlertEventType} from 'app-events';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        large: {
            width: theme.spacing(40),
            height: theme.spacing(40),
        },
    }),
);

export function PerfilLabPage(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [tab, setTab] = useState(0)
    const laboratorio = useSelector<AppRootState, Laboratorio | undefined>(({session}) => session.laboratorio);
    const [loading, setLoading] = useState(false);
    const [novaImagem, setNovaImagem] = useState<HTMLCanvasElement | undefined>();
    const [endereco, setEndereco] = useState<EnderecoLaboratorio | undefined>();
    const [configuracaoFrete, setConfiguracaoFrete] = useState<ConfiguracaoFrete | undefined>();
    const [paginacaoUsuarios, setPaginacaoUsuarios] = useState<Paginacao<Usuario> | null>();
    const [usuarios, setUsuarios] = useState<Usuario[] | null>(null);
    const [showFormUsuario, setShowFormUsuario] = useState(false);
    const [showFormSenha, setShowFormSenha] = useState(false);
    const [usuarioForm, setUsuarioForm] = useState<Usuario>();
    const [dropdownUsuarios, setDropdownUsuarios] = useState<{ [key: number]: boolean }>({})
    const [localizacaoEndereco, setLocalizacaoEndereco] = useState<{ latitude: number, longitude: number } | null>()
    const [dataAlteracaoEndereco, setDataAlteracaoEndereco] = useState<any>()
    const [showFormLocalizacao, setShowFormLocalizacao] = useState(false);

    useEffect(() => {
        if (tab === 1 && endereco === undefined) {
            LaboratorioService.obterEnderecoLaboratorio().then(endereco => {
                setEndereco(endereco);
            }, () => {
                setEndereco(new EnderecoLaboratorio());
            })
        }
        if (tab === 3 && configuracaoFrete === undefined) {
            LaboratorioService.obterConfiguracaoFreteLaboratorio().then(config => {
                setConfiguracaoFrete(config);
            }, () => {
                setConfiguracaoFrete(new ConfiguracaoFrete());
            })
        }
        if (tab === 2 && paginacaoUsuarios === undefined) {
            UsuarioService.obterListaDeUsuarios().then(async pag => {
                setPaginacaoUsuarios(pag);
                setUsuarios(await pag.getItensProximaPagina());
            }, () => {
                setPaginacaoUsuarios(null)
            })
        }
    })

    const headleSubmitPerfil = (data: any, callbackErro?: any) => {
        setLoading(true);
        LaboratorioService.atualizarPerfilLaboratorio(data.email, data.telefone, novaImagem).then(lab => {
            dispatch(labChange(new Laboratorio((lab))));
            setLoading(false);
        }, (err) => {
            if (err.status === 400 && err.data && callbackErro) {
                callbackErro(err.data)
            }
            setLoading(false);
        })
    }

    const headleSubmitEndereco = (data: any, callbackErro?: any) => {
        if (endereco) {
            setLoading(true);
            LaboratorioService.atualizarEnderecoLaboratorio({
                ...endereco,
                cidade: endereco.cidade_id,
                estado: endereco.estado_id,
                localizacao: {
                    latitude: data.localizacao.lat,
                    longitude: data.localizacao.lng
                }
            }).then(laboratorio => {
                dispatch(labChange(new Laboratorio(laboratorio)));
                setShowFormLocalizacao(false);
                setLoading(false);
            }, (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data);
                }
                setLoading(false);
            })
        }
    }

    const headleSubmitConfigFrente = (data: any, callbackErro?: any) => {
        setLoading(true);
        LaboratorioService.atualizarConfigFrete({
            ...data,
            laboratorio: laboratorio?.id,
            id: configuracaoFrete?.id
        }).then(configFrete => {
            setConfiguracaoFrete(configFrete)
            setLoading(false);
        }, (err) => {
            if (err.status === 400 && err.data && callbackErro) {
                callbackErro(err.data);
            }
            setLoading(false);
        })
    }

    const headlePreSubmitEndereco = async (data: any, callbackErro?: any) => {
        if (endereco) {
            setLoading(true);
            const {localizacao} = await GeoService.obterLatLngPorEndereco({
                ...endereco,
                estado: endereco.estado_id,
                cidade: endereco.cidade_id
            })
            setLocalizacaoEndereco(localizacao);
            setLoading(false);
            setShowFormLocalizacao(true);
        }
    }


    const atualizarUsuarioNaPagina = (usuarioEditado: Usuario) => {
        setUsuarios(u => {
            const users = [...(u || [])];
            const i = users.findIndex(x => x.id === usuarioEditado.id)
            if (i > -1) {
                users[i] = usuarioEditado;
            }
            return users;
        })
    }

    const headleSubmitUsuario = (data: any, callbackErro?: any) => {
        if (usuarioForm && usuarioForm.id) {
            setLoading(true);
            UsuarioService.atualizarUsuario(usuarioForm).then(usuarioEditado => {
                atualizarUsuarioNaPagina(usuarioEditado);
                setLoading(false);
                setShowFormUsuario(false);
            }, (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data)
                }
                setLoading(false);
            })
        } else if (usuarioForm) {
            UsuarioService.cadastrarUsuario(usuarioForm).then(usuarioCadastrado => {
                setPaginacaoUsuarios(undefined);
                setLoading(false);
                setShowFormUsuario(false);
            }, (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data)
                }
                setLoading(false);
            })
        }
    }

    const headleSubmitNovaSenha = (data: any, callbackErro?: any) => {
        if (usuarioForm && usuarioForm.id) {
            setLoading(true);
            UsuarioService.atualizarSenhaUsuario(usuarioForm.id, data.senha, data.confirmar_senha).then(usuarioEditado => {
                setLoading(false);
                setShowFormSenha(false);
                AlertEvent.emit(AlertEventType.SUCCESS, "Senha alterada com sucesso!")
            }, (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data)
                }
                setLoading(false);
            })
        }
    }

    const headleSubmitLogo = (logo: HTMLCanvasElement) => {
        setNovaImagem(logo)
    }

    const toogleAtivacaoUsuario = async (usuario: Usuario) => {
        if (usuario.ativo) {
            UsuarioService.bloquearUsuario(usuario).then(usuarioEditado => {
                atualizarUsuarioNaPagina(usuarioEditado);
                setLoading(false);
                AlertEvent.emit(AlertEventType.SUCCESS, "Operação realizada com sucesso!")
            }, (err) => {
                setLoading(false);
            })
        } else {
            UsuarioService.ativarUsuario(usuario).then(usuarioEditado => {
                atualizarUsuarioNaPagina(usuarioEditado);
                setLoading(false);
                AlertEvent.emit(AlertEventType.SUCCESS, "Operação realizada com sucesso!")
            }, (err) => {
                setLoading(false);
            })
        }
    }

    return (
        <PageContainer heading={'Perfil Laboratório'} breadcrumbs={[]}>
            <GridContainer justify="center"
                           alignItems="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Tabs
                                value={tab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered={true}
                                scrollButtons="on">
                                <Tab label="Laboratório" onClick={() => setTab(0)}/>
                                <Tab label="Endereço" onClick={() => setTab(1)}/>
                                <Tab label="Gestão de Acessos" onClick={() => setTab(2)}/>
                                <Tab label="Configuração de Frete" onClick={() => setTab(3)}/>
                            </Tabs>
                            <hr/>
                            <br/>
                            {tab === 0 && <GridContainer justify={'center'}>
                                <Grid item xs={12} md={6}>
                                    <Typography align={'center'}>
                                        <BtnSelectAndCropImage onSave={imageCrop => {
                                            headleSubmitLogo(imageCrop);
                                        }}>
                                            <Avatar variant="square" className={classes.large}
                                                    src={novaImagem?.toDataURL() || laboratorio?.logo}/>
                                        </BtnSelectAndCropImage>
                                    </Typography>
                                    {laboratorio &&
                                    <Formulario name="Perfil" fields={laboratorio.getFieldsFormulario()}
                                                loading={loading}
                                                onSubmit={headleSubmitPerfil} textSubmitButton="Salvar"/>}
                                </Grid>
                            </GridContainer>}
                            {tab === 1 && <GridContainer justify={'center'}>
                                <Grid item xs={12} md={6}>
                                    <Loader noShow={endereco !== undefined}/>
                                    {endereco &&
                                    <Formulario name="Perfil" fields={endereco.getFieldsFormulario()}
                                                loading={loading}
                                                onSubmit={headlePreSubmitEndereco}
                                                textSubmitButton="Continuar"/>}
                                    <ModalCenter title={'Para continuar é necessário informar a localização'}
                                                 open={showFormLocalizacao} onClose={() => {
                                        setShowFormLocalizacao(s => !s);
                                        setLocalizacaoEndereco(undefined)
                                    }}>
                                        <Formulario name="Perfil" fields={[
                                            {
                                                name: 'localizacao',
                                                label: 'Defina sua Localização',
                                                type: 'localizacao',
                                                validators: {
                                                    required: true
                                                },
                                                valueDefault: localizacaoEndereco ? {
                                                    lat: localizacaoEndereco.latitude,
                                                    lng: localizacaoEndereco.longitude
                                                } : undefined
                                            }]} loading={loading}
                                                    onSubmit={headleSubmitEndereco}
                                                    textSubmitButton="Salvar"/>
                                    </ModalCenter>
                                </Grid>
                            </GridContainer>}
                            {tab === 2 && <GridContainer justify={'center'}>
                                <Grid item xs={12} md={12}>
                                    <Typography>
                                        <br/>
                                        <Button variant={'contained'} color={'primary'}
                                                onClick={() => {
                                                    setUsuarioForm(new Usuario());
                                                    setShowFormUsuario(true);
                                                }}>
                                            Novo Usuário
                                        </Button>
                                    </Typography>
                                    <ModalCenter title={'Usuário Laboratório'} open={showFormUsuario}
                                                 onClose={() => setShowFormUsuario(s => !s)}>
                                        {usuarioForm &&
                                        <Formulario name="Perfil"
                                                    fields={usuarioForm.getFieldsFormulario()}
                                                    loading={loading}
                                                    onSubmit={headleSubmitUsuario}
                                                    textSubmitButton="Salvar"/>}
                                    </ModalCenter>
                                    <ModalCenter open={showFormSenha} title={'Alteração de Senha'}
                                                 onClose={() => setShowFormSenha(s => !s)}>
                                        {usuarioForm &&
                                        <Formulario name="Perfil" fields={[
                                            {
                                                name: 'senha',
                                                label: 'Nova senha de acesso',
                                                type: 'password'
                                            },
                                            {
                                                name: 'confirmar_senha',
                                                label: 'Confirmar nova senha',
                                                type: 'password'
                                            }
                                        ]}
                                                    loading={loading}
                                                    onSubmit={headleSubmitNovaSenha}
                                                    textSubmitButton="Salvar"/>}
                                    </ModalCenter>
                                    <Box className="Cmt-table-responsive">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Imagem</TableCell>
                                                    <TableCell>Nome</TableCell>
                                                    <TableCell>Categoria</TableCell>
                                                    <TableCell>Precificação</TableCell>
                                                    <TableCell>Valor</TableCell>
                                                    <TableCell>Ativo</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {usuarios && usuarios.map(x =>
                                                    <StyledTableRow key={x.id}>
                                                        <TableCell>{x.nome}</TableCell>
                                                        <TableCell>{x.email}</TableCell>
                                                        <TableCell>{x.celular || '-'}</TableCell>
                                                        <TableCell>{x.getPermissoesDisplay()}</TableCell>
                                                        <TableCell>
                                                            <Switch
                                                                disabled={loading}
                                                                checked={x.ativo}
                                                                onChange={() => toogleAtivacaoUsuario(x)}
                                                                color="secondary"
                                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {x.id && <IconButton color={'secondary'} onClick={() => {
                                                                setUsuarioForm(x);
                                                                setShowFormUsuario(true);
                                                            }}><Edit/></IconButton>}
                                                            {x.id && <IconButton onClick={() => {
                                                                setUsuarioForm(x);
                                                                setShowFormSenha(true);
                                                            }}><SecurityRounded/></IconButton>}
                                                        </TableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                    <Loader
                                        noShow={paginacaoUsuarios !== undefined && !!usuarios && usuarios.length > 0}/>
                                    {paginacaoUsuarios &&
                                    <ControleDePaginacao
                                        totalPaginas={paginacaoUsuarios.getQuantidadeDePaginas()}
                                        onPageChange={(pg) => {
                                            setUsuarios(null);
                                            paginacaoUsuarios.getItensDaPagina(pg).then(us => {
                                                setUsuarios(us);
                                            })
                                        }}/>}
                                </Grid>
                            </GridContainer>}
                            {tab === 3 && <GridContainer justify={'center'}>
                                <Grid item xs={12} md={6}>
                                    <Loader noShow={configuracaoFrete !== undefined}/>
                                    {configuracaoFrete &&
                                    <Formulario name="ConfiguracaoFrete" key={'ConfiguracaoFrete'}
                                                fields={configuracaoFrete.getFieldsFormulario()}
                                                loading={loading}
                                                onSubmit={headleSubmitConfigFrente}
                                                textSubmitButton="Salvar"/>}
                                </Grid>
                            </GridContainer>}
                        </CardContent>
                    </Card>
                </Grid>
            </GridContainer>
        </PageContainer>
    );
}
