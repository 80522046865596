import React, {useEffect, useState} from 'react';
import Star from '@material-ui/icons/Star';
import {Wallet} from 'models/Wallet';
import {WalletService} from 'services/WalletService';
import {Pedido} from 'models/Pedido';
import {ApiService} from 'services/ApiService';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import {Card, fade, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {StatisticsCard} from '@jumbo/components/Common';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import CmtAdvCard from '@coremat/CmtAdvCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Moeda} from 'components/Moeda';
import {Remove, Visibility, VisibilityOff} from '@material-ui/icons';
import {Loader} from 'components/Loader';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import {LocalData} from 'components/LocalData';

const useStyles = makeStyles<any>(theme => ({
    cardPedidos: {
        color: theme.palette.common.black,
        height: 300
    },
    cardRoot: {
        color: theme.palette.common.white,
        minHeight: 165
    },
    titleRoot: {
        fontSize: 12,
        fontWeight: 400,
        textTransform: 'uppercase',
        letterSpacing: 0.4,
    },
    subTitle: {
        color: theme.palette.text.secondary,
    },
    linkBtn: {
        marginLeft: -6,
    },
    scrollbarRoot: {
        height: 332,
    },
    tableRowCellRoot: {
        fontSize: 12,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 16,
        letterSpacing: 0.4,
        color: theme.palette.common.dark,
        borderBottom: '0 none',
        '&:first-child': {
            paddingLeft: 24,
        },
        '&:last-child': {
            textAlign: 'right',
            paddingRight: 24,
        },
    },
    tableCellRoot: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 14,
        letterSpacing: 0.25,
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        borderBottom: '0 none',
        '&:first-child': {
            paddingLeft: 24,
        },
        '&:last-child': {
            textAlign: 'right',
            paddingRight: 24,
        },
        '& .Cmt-avatar-more': {
            fontSize: 10,
            color: theme.palette.primary.main,
        },
    },
    tableRowRoot: {
        cursor: 'pointer',
        position: 'relative',
        transition: 'all .2s',
        borderTop: `solid 1px ${theme.palette.borderColor.main}`,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.08),
            transform: 'translateY(-4px)',
            boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
            borderTopColor: 'transparent',
            '& $tableCellRoot': {
                color: theme.palette.text.primary,
                '&:last-child': {
                    color: theme.palette.error.main,
                },
                '&.success': {
                    color: theme.palette.success.main,
                },
            },
        },
        '&:last-child': {
            borderBottom: `solid 1px ${theme.palette.borderColor.main}`
        },
    },
}));

export function HomePage(props: any) {
    const [verSaldo, setVerSaldo] = useState(false);
    const [wallet, setWallet] = useState<Wallet | null>();
    const [pedidos, setPedidos] = useState<Pedido[]>();

    const classes = useStyles();

    useEffect(() => {
        if (wallet === undefined) {
            WalletService.obterWallet().then(w => {
                setWallet(w);
            }, () => {
                setWallet(null);
            });
        }
    })

    useEffect(() => {
        if (pedidos === undefined) {
            ApiService.getAll('/pedidos').then(async data => {
                setPedidos(data.results.map((x: any) => new Pedido(x)));
            }, () => {
                setPedidos([])
            });
        }

    }, [wallet]);

    return (
        <PageContainer heading="" breadcrumbs={[]}>
            <GridContainer justify="center"
                           alignItems="center">
                <Grid item xs={3}>
                    <StatisticsCard
                        className={classes.cardRoot}
                        backgroundColor="#7D38DF"
                        title="Avaliações"
                        titleProps={{
                            variant: 'inherit',
                            component: 'h4',
                            className: classes.titleRoot,
                        }}
                        amount={<GridContainer justify="flex-start"
                                               alignItems="flex-start">
                            <Grid item>
                                <Star className="text-warning" fontSize="large"/>
                            </Grid>
                            <Grid item>
                                        <span style={{
                                            fontSize: '200%',
                                        }}>0,00</span>
                            </Grid>
                        </GridContainer>}
                        description="Nenhuma avaliação no momento">
                    </StatisticsCard>
                </Grid>
                <Grid item xs={3}>
                    <StatisticsCard
                        description=""
                        className={classes.cardRoot}
                        backgroundColor="#3B9FE2"
                        title="Dentistas Convidados"
                        titleProps={{
                            variant: 'inherit',
                            component: 'h4',
                            className: classes.titleRoot,
                        }}
                        amount={<span style={{
                            fontSize: '200%',
                        }}>0</span>}>
                    </StatisticsCard>
                </Grid>
                <Grid item xs={3}>
                    <StatisticsCard
                        description=""
                        className={classes.cardRoot}
                        backgroundColor="#BE4ED0"
                        title="Vendas Mês"
                        titleProps={{
                            variant: 'inherit',
                            component: 'h4',
                            className: classes.titleRoot,
                        }}
                        amount={<span style={{
                            fontSize: '200%',
                        }}>0</span>}>
                    </StatisticsCard>
                </Grid>
                <Grid item xs={3}>
                    <StatisticsCard
                        className={classes.cardRoot}
                        backgroundColor="#E86C63"
                        description=""
                        title="Dentistas Favoritos"
                        titleProps={{
                            variant: 'inherit',
                            component: 'h4',
                            className: classes.titleRoot,
                        }}
                        amount={<span style={{
                            fontSize: '200%',
                        }}>0</span>}>
                    </StatisticsCard>
                </Grid>
            </GridContainer>
            <GridContainer>
                <Grid item xs={6}>
                    <CmtAdvCard className={classes.cardPedidos}>
                        <CmtCardHeader
                            titleProps={{
                                variant: 'h4',
                                component: 'div',
                            }}
                            title={<Box mb={{xs: 4, md: 6}} display="flex" flexDirection="row" flexWrap="wrap">
                                <Box mr={2} mb={2}>
                                    <Typography component="div" variant="h6">
                                        Minha Carteira
                                    </Typography>
                                </Box>
                                <Box ml={'auto'} mb={2}>
                                    <Button style={{marginTop: '-5px'}} onClick={() => setVerSaldo(v => !v)}>
                                        {verSaldo ? <VisibilityOff/> : <Visibility/>}
                                    </Button>
                                </Box>
                            </Box>}
                        />
                        <CmtAdvCardContent
                            title={<Box mb={2} display="flex" flexDirection="row" alignItems="center">
                                {!!wallet && <Typography component="div" variant="h1">
                                    {verSaldo && <Moeda value={wallet.disponivel}/>}
                                    {!verSaldo && <>R$ <Remove/></>}
                                </Typography>}
                            </Box>}
                            subTitle={'Saldo atual'}
                            subTitleProps={{
                                variant: 'body2',
                                component: 'p',
                                className: classes.subTitle,
                            }}
                            extraContent={<Box mt={{xs: 6, md: 8, xl: 10}}>
                                <Box mb={{xs: 4, md: 6}} display="flex" flexDirection="row" flexWrap="wrap">
                                    <Box mr={2} mb={2}>
                                    </Box>
                                    <Box mb={2}>
                                        <Button color="secondary" variant="contained"
                                                onClick={() => props.history.push(`${props.basePath.url}/wallet`)}>Ver
                                            Extrato</Button>
                                    </Box>
                                </Box>
                            </Box>}
                            reverseDir>
                            <Box width={1}>
                                <Typography component="div" variant="h6" className={classes.titleRoot}>
                                    A Liberar
                                </Typography>
                                {!wallet && <Loader/>}
                                {wallet && <>
                                    <h3>
                                        {verSaldo && <Moeda value={wallet.liberar}/>}
                                        {!verSaldo && <>R$ <Remove/></>}
                                    </h3>
                                </>}
                                <br/>
                                <Typography component="div" variant="h6" className={classes.titleRoot}>
                                    Total
                                </Typography>
                                {!wallet && <Loader/>}
                                {wallet && <>
                                    <h3>
                                        {verSaldo && <Moeda value={wallet.liberar + wallet.disponivel}/>}
                                        {!verSaldo && <>R$ <Remove/></>}
                                    </h3>
                                </>}
                            </Box>
                        </CmtAdvCardContent>
                    </CmtAdvCard>
                </Grid>
                <Grid item xs={6}>
                    <CmtCard className={classes.cardPedidos}>
                        <CmtCardHeader
                            title={<Box mb={{xs: 4, md: 6}} display="flex" flexDirection="row" flexWrap="wrap">
                                <Box mr={2} mb={2}>
                                    <Typography component="div" variant="h6">
                                        Últimos pedidos
                                    </Typography>
                                </Box>
                                <Box ml={'auto'} mb={2}>
                                    <Button color='secondary' style={{marginTop: '-5px'}} onClick={() => {}}>
                                        Ver Pedidos
                                    </Button>
                                </Box>
                            </Box>}>
                        </CmtCardHeader>
                        <CmtCardContent style={{
                            marginTop: -50
                        }}>
                            <PerfectScrollbar className={classes.scrollbarRoot}>
                                <Box className="Cmt-table-responsive">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableRowCellRoot}>Dentista</TableCell>
                                                <TableCell className={classes.tableRowCellRoot}>Status</TableCell>
                                                <TableCell className={classes.tableRowCellRoot}>Data</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!!pedidos && pedidos.map(row => (
                                                <TableRow key={row.id} className={clsx(classes.tableRowRoot)} onClick={() => props.history.push(`${props.basePath.url}/pedido/${row.id}`)}>
                                                    <TableCell className={classes.tableCellRoot}>
                                                        <Box>{row.dentista?.nome}</Box>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellRoot}>
                                                        <Box>{row.status_display}</Box>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellRoot}>
                                                        <Box><LocalData data={row.data_cadastro}/></Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </CmtCardContent>
                    </CmtCard>
                </Grid>
            </GridContainer>

        </PageContainer>
    );
}
