export class Transacao {
    public id?: number;
    public wallet?: number;
    public descricao?: string;
    public saldo?: number;
    public _valor?: number;
    public operacao?: number;
    public data_cadastro?: Date;

    constructor(defaultValue?: any) {
        if (defaultValue) {
            this.id = defaultValue.id;
            this.wallet = defaultValue.wallet;
            this.descricao = defaultValue.descricao;
            this.operacao = defaultValue.operacao;
            this.saldo = defaultValue.saldo === undefined ? undefined : Number(defaultValue.saldo);
            this._valor = defaultValue.valor === undefined ? undefined : Number(defaultValue.valor);
            this.data_cadastro = defaultValue.data_cadastro ? new Date(defaultValue.data_cadastro) : undefined;
        }
    }

    public get valor() {
        return this.operacao === 0 ? this._valor || 0 : Number(this._valor) * -1
    }
}
