import {Paginacao} from 'util/Paginacao';
import {ApiService} from 'services/ApiService';
import {Servico} from 'models/Servico';

export class ServicosService {
    private static SERVICO_URL = '/servicos-lab'

    public static async obterListaDeServicos(): Promise<Paginacao<Servico>> {
        return await ApiService.getAll(ServicosService.SERVICO_URL).then(data => new Paginacao(ServicosService.SERVICO_URL, Servico, data))
    }

    public static async obterServico(id: string | number): Promise<Servico> {
        return await ApiService.get(ServicosService.SERVICO_URL, String(id)).then(data => new Servico(data))
    }

    public static async cadatrarNovoServico(servico: Servico, imagem?: HTMLCanvasElement): Promise<Servico> {
        const dataFrom = new FormData();
        dataFrom.set('nome', servico.nome || '');
        dataFrom.set('categoria', String(servico.categoria === undefined ? '' : servico.categoria));
        dataFrom.set('precificacao', String(servico.precificacao === undefined ? '' : servico.precificacao));
        dataFrom.set('valor', String(servico.valor === undefined ? '' : servico.valor));
        dataFrom.set('dente', String(servico.dente === undefined ? '' : servico.dente));
        dataFrom.set('prazo', String(servico.prazo === undefined ? '' : servico.prazo));
        dataFrom.set('gengiva', String(servico.gengiva === undefined ? '' : servico.gengiva));
        dataFrom.set('descricao', String(servico.descricao === undefined ? '' : servico.descricao));
        dataFrom.set('arquivo', String(servico.arquivo === undefined ? false : servico.arquivo));
        dataFrom.set('remanecente', String(servico.remanecente === undefined ? false : servico.remanecente));
        dataFrom.set('ativo', String(servico.ativo === undefined ? '' : servico.ativo));
        if (imagem) {
            dataFrom.set('imagem', new File([await new Promise<Blob>((resolve, reject) => {
                imagem.toBlob(b => {
                    if (b)
                        resolve(b)
                    else
                        reject()
                });
            })], 'imagem.png'));
        }
        return await ApiService.post(ServicosService.SERVICO_URL, dataFrom).then(data => new Servico(data))
    }

    public static async alterarServico(servico: Servico, imagem?: HTMLCanvasElement): Promise<Servico> {
        const dataFrom = new FormData();
        dataFrom.set('nome', servico.nome || '');
        dataFrom.set('categoria', String(servico.categoria === undefined ? '' : servico.categoria));
        dataFrom.set('precificacao', String(servico.precificacao === undefined ? '' : servico.precificacao));
        dataFrom.set('valor', String(servico.valor === undefined ? '' : servico.valor));
        dataFrom.set('dente', String(servico.dente === undefined ? '' : servico.dente));
        dataFrom.set('prazo', String(servico.prazo === undefined ? '' : servico.prazo));
        dataFrom.set('gengiva', String(servico.gengiva === undefined ? '' : servico.gengiva));
        dataFrom.set('descricao', String(servico.descricao === undefined ? '' : servico.descricao));
        dataFrom.set('arquivo', String(servico.arquivo === undefined ? false : servico.arquivo));
        dataFrom.set('remanecente', String(servico.remanecente === undefined ? false : servico.remanecente));
        dataFrom.set('ativo', String(servico.ativo === undefined ? '' : servico.ativo));
        if (imagem) {
            dataFrom.set('imagem', new File([await new Promise<Blob>((resolve, reject) => {
                imagem.toBlob(b => {
                    if (b)
                        resolve(b)
                    else
                        reject()
                });
            })], 'imagem.png'));
        }
        return await ApiService.put(ServicosService.SERVICO_URL, String(servico?.id), dataFrom).then(data => new Servico(data))
    }


    public static async bloquearSevico(servico: Servico) {
        return await ApiService.patch(ServicosService.SERVICO_URL, String(servico?.id), {ativo: false}).then(data => new Servico(data))
    }

    public static async ativarServico(servico: Servico) {
        return await ApiService.patch(ServicosService.SERVICO_URL, String(servico?.id), {ativo: true}).then(data => new Servico(data))
    }

}
