// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Autocomplete} from '@material-ui/lab';

export interface OptionType {
    label: string,
    value: any,
    disabled?: boolean
}

export default function Select2(props: {
    options: OptionType[] | (() => Promise<OptionType[]>),
    placeholder?: string,
    label?: string,
    readonly?: boolean,
    disabled?: boolean,
    name?: string,
    defaultValue?: OptionType[] | OptionType,
    error?: boolean,
    multiple?: boolean,
    className?: string
    fullWidth?: boolean,
    onChange: (selected: OptionType[] | OptionType | null) => void
    value: OptionType[] | OptionType | null
}) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<OptionType[]>(Array.isArray(props.options) ? props.options : []);
    const loading = open && options.length === 0;
    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        if (!Array.isArray(props.options)) {
            (async () => {
                const response = await (props.options as (() => Promise<OptionType[]>))();
                if (active) {
                    setOptions(response);
                }
            })();

            return () => {
                active = false;
            };
        }
    }, [loading]);
    React.useEffect(() => {
        if (!open && !Array.isArray(props.options)) {
            setOptions([]);
        }
    }, [open]);
    return (
        <Autocomplete
            fullWidth={props.fullWidth}
            className={props.className}
            multiple={props.multiple}
            placeholder={props.placeholder}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option: OptionType, value: OptionType) => option.value === value.value}
            getOptionLabel={(option: OptionType) => option.label}
            options={options}
            loading={loading}
            disabled={props.disabled}
            getOptionDisabled={option => !!option.disabled}
            defaultValue={props.defaultValue}
            value={props.value}
            onChange={(event: any, newValue: OptionType | OptionType[] | null) => {
                props.onChange(newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    disabled={props.disabled}
                    error={props.error}
                    fullWidth={props.fullWidth}
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        readOnly: props.readonly,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
