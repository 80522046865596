import {createStyles, withStyles} from '@material-ui/styles';
import {TableRow, Theme} from '@material-ui/core';


const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            cursor: 'pointer'
        },
    }),
)(TableRow);

export default StyledTableRow;
