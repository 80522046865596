import React, {PropsWithChildren} from 'react';
import { CardContent, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: props => ({
    padding: 24,
  }),
}));

const CmtCardContent = (props:PropsWithChildren<any>) => {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  return (
    <CardContent className={clsx(classes.root, 'Cmt-card-content', className)} {...rest}>
      {children}
    </CardContent>
  );
};

export default CmtCardContent;
