import {IJsonFromField} from 'components/InputFormulario/types';

export class Laboratorio {
    public id?: number;
    public nome?: string;
    public razao_social?: string;
    public email?: string;
    public telefone?: string;
    public cpf_cnpj?: string;
    public cro?: string;
    public estado_cro?: number;
    public logo?: string;

    constructor(defaultValue: any) {
        if (defaultValue) {
            this.nome = defaultValue.nome;
            this.razao_social = defaultValue.razao_social;
            this.email = defaultValue.email;
            this.telefone = defaultValue.telefone;
            this.cpf_cnpj = defaultValue.cpf_cnpj;
            this.cro = defaultValue.cro;
            this.estado_cro = defaultValue.estado_cro;
            this.id = defaultValue.id;
            this.logo = defaultValue.logo;
        }
    }

    getFieldsFormulario(): IJsonFromField[]{
        return [
            {
                name: 'nome',
                label: 'Nome do Laboratório',
                type: 'text',
                disabled: true,
                valueDefault: this.nome
            },
            {
                name: 'razao_social',
                label: 'Razão Social',
                type: 'text',
                disabled: true,
                valueDefault: this.razao_social
            }, {
                name: 'cpf_cnpj',
                label: 'CPF/CNPJ',
                type: 'text',
                disabled: true,
                valueDefault: this.cpf_cnpj
            },
            {
                name: 'email',
                label: 'Email',
                type: 'email',
                validators: {
                    required: true
                },
                valueDefault: this.email
            },
            {
                name: 'telefone',
                label: 'Telefone de Contato',
                type: 'tel',
                valueDefault: this.telefone,
                validators: {
                    required: true
                },
            },

        ]
    }
}
