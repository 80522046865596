import {Paginacao} from 'util/Paginacao';
import {ApiService} from 'services/ApiService';
import {Wallet} from 'models/Wallet';
import {Transacao} from 'models/Transacao';
import {InstituicaoBancaria} from 'models/InstituicaoBancaria';
import {ContaBancaria} from 'models/ContaBancaria';

export class WalletService {
    private static WALLET_URL = '/wallet-lab'
    private static EXTRATO_URL = '/extrato-lab'
    private static BANCOS_URL = '/bancos'
    private static CONTAS_URL = '/conta-bancaria-lab'
    private static SAQUE_URL = '/solicitacao-saque-lab'

    public static async obterWallet(): Promise<Wallet> {
        return await ApiService.getAll(WalletService.WALLET_URL).then(data => data as Wallet)
    }

    public static async obterTransacoes(): Promise<Paginacao<Transacao>> {
        return await ApiService.getAll(WalletService.EXTRATO_URL).then(data => new Paginacao(WalletService.EXTRATO_URL, Transacao, data))
    }

    public static async obterInstituicoesBancarias(): Promise<InstituicaoBancaria[]> {
        return await ApiService.getAll(WalletService.BANCOS_URL)
    }

    public static async obterListaDeContasCadastradas(): Promise<ContaBancaria[]> {
        return await ApiService.getAll(WalletService.CONTAS_URL).then(x => x.map((c: any) => new ContaBancaria(c)))
    }

    public static async cadastrarContaBancaria(conta: ContaBancaria): Promise<ContaBancaria> {
        return await ApiService.post(WalletService.CONTAS_URL, conta).then(x => new ContaBancaria(x))
    }

    public static async editarContaBancaria(conta: ContaBancaria): Promise<ContaBancaria> {
        return await ApiService.put(WalletService.CONTAS_URL, String(conta.id), conta).then(x => new ContaBancaria(x))
    }

    public static async removerContaBancaria(id: number): Promise<any> {
        return await ApiService.delete(WalletService.CONTAS_URL, String(id))
    }

    public static async realizarSolicitacaoDeSaque(
        valor: number,
        codigo_banco: string,
        agencia: string,
        conta: string
    ) {
        return await ApiService.post(WalletService.SAQUE_URL, {
            valor, codigo_banco, agencia, conta
        })
    }
}
