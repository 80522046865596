import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {HomePage} from './HomePage';
import {PedidosPage} from './PedidosPage';
import {MinhaCarteiraPage} from './MinhaCarteiraPage';
import {ServicosPage} from './ServicosPage';
import {PromocoesPage} from './PromocoesPage';
import {PerfilLabPage} from './PerfilLabPage';
import {PedidoPage} from './PedidoPage';
import {useDispatch, useSelector} from 'react-redux';
import {AuthService} from '../../services/AuthService';
import {userSignIn, userSignOut} from '../../redux/actions';
import {AlertEvent, AlertEventType, ApiEvent} from '../../app-events';
import {ApiEventsType} from '../../services/ApiService';
import {Loader} from '../../components/Loader';
import {ServicoPage} from './ServicoPage';

const RoutesPages = (props) => {

    const dispatch = useDispatch();
    const {usuario, laboratorio} = useSelector(({session}) => session);
    const [loading, setLoading] = useState(true);
    const {match, location} = props;

    useEffect(() => {
        if (!usuario || !laboratorio) {
            AuthService.obterSessao().then((sessao) => {
                dispatch(userSignIn(sessao.usuario, sessao.laboratorio));
                setLoading(false);
            }, () => {
                props.history.push('/login?goBack=' + location.pathname);
            })
        } else {
            setLoading(false);
        }
    }, [usuario, laboratorio]);

    const apiHandler = (error) => {
        if (error.status === 403) {
            dispatch(userSignOut());
            return props.history.push('/login?goBack=' + location.pathname);
        }
        if (error.status === 500) {
            AlertEvent.emit(AlertEventType.DANGER, 'Tente novamente mais tarde!')
            return;
        }
    }

    useEffect(() => {
        ApiEvent.addListener(ApiEventsType.HTTP_ERROR, apiHandler);
        return () => {
            ApiEvent.removeListener(ApiEventsType.HTTP_ERROR, apiHandler);
        }
    })

    if (location.pathname === match.url) {
        return (<Redirect to={`${match.url}/home`}/>);
    }

    if (loading) {
        return <Loader/>;
    }

    return <Switch>
        <Route path={`${match.url}/home`} render={props => {
            return <HomePage {...props} basePath={match}/>
        }}/>
        <Route path={`${match.url}/pedidos`} render={props => {
            return <PedidosPage {...props}/>
        }}/>
        <Route path={`${match.url}/pedido/:id`} render={props => {
            return <PedidoPage {...props}/>
        }}/>
        <Route path={`${match.url}/wallet`} render={props => {
            return <MinhaCarteiraPage {...props}/>
        }}/>
        <Route path={`${match.url}/servicos`} render={props => {
            return <ServicosPage {...props}/>
        }}/>
        <Route path={`${match.url}/servico/:id?`} render={props => {
            return <ServicoPage {...props}/>
        }}/>
        <Route path={`${match.url}/promocoes`} render={props => {
            return <PromocoesPage {...props}/>
        }}/>
        <Route path={`${match.url}/perfil`} render={props => {
            return <PerfilLabPage {...props}/>
        }}/>

    </Switch>;

}

export default withRouter(RoutesPages);

