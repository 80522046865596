import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';

export function LocalData(props: { data?: Date | string }) {
    if (!props.data)
        return null;
    if (props.data instanceof Date) {
        return <Typography>{props.data.toLocaleString()}</Typography>
    } else {
        return <Typography>{moment(props.data).toDate().toLocaleString()}</Typography>
    }
}
