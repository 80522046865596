import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import Box from '@material-ui/core/Box';

export function Loader(props: { noShow?: boolean }) {
    if (!props.noShow)
        return <Box component={'div'} style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgress color={'inherit'}/>
        </Box>
    return <div/>
}
