import {ApiService} from 'services/ApiService';
import {Usuario} from 'models/Usuario';
import {Laboratorio} from 'models/Laboratorio';

export class AuthService {
    private static URL_LOGIN = '/login';
    private static URL_SESSAO = '/sessao';

    static async realizarLogin(email: string, senha: string): Promise<{ usuario: Usuario, laboratorio: Laboratorio }> {
        return ApiService.post(AuthService.URL_LOGIN, {
            usuario: email,
            senha: senha
        }).then(data => {
            return { usuario: new Usuario(data.usuario), laboratorio: new Laboratorio(data.laboratorio)}
        });
    }

    static async obterSessao(): Promise<{ usuario: Usuario, laboratorio: Laboratorio }> {
        return await ApiService.get(AuthService.URL_SESSAO).then(data => {
            return { usuario: new Usuario(data.usuario), laboratorio: new Laboratorio(data.laboratorio)}
        });
    }

    static async realizarLogout() {
        return await ApiService.delete(AuthService.URL_SESSAO, '0');
    }
}
