import {IJsonFromField} from 'components/InputFormulario/types';

export const CATEGORIA_SERVICO: { [key: number]: string } = {
    0: 'COMUM',
    1: 'ACRÍLICO',
    2: 'RESINA FOTO',
    3: 'METAL',
    4: 'CERÂMICA',
}
export const TIPO_PRECIFICACAO: { [key: number]: string } = {
    0: 'POR ARCADA',
    1: 'POR ELEMENTO'
}

export class Servico {
    public id?: number;
    public nome?: string;
    public descricao?: string;
    public categoria?: number;
    public precificacao?: number;
    public valor?: number;
    public gengiva?: boolean;
    public dente?: boolean;
    public remanecente?: boolean;
    public arquivo?: boolean;
    public prazo?: number;
    public imagem?: string;
    public ativo?: boolean;
    public data_cadastro?: Date;

    constructor(defaultValues?: any) {
        if (defaultValues) {
            this.id = defaultValues.id;
            this.nome = defaultValues.nome;
            this.descricao = defaultValues.descricao;
            this.categoria = defaultValues.categoria;
            this.precificacao = defaultValues.precificacao;
            this.valor = defaultValues.valor ? Number(defaultValues.valor) : undefined;
            this.gengiva = defaultValues.gengiva;
            this.dente = defaultValues.dente;
            this.prazo = defaultValues.prazo;
            this.imagem = defaultValues.imagem;
            this.ativo = defaultValues.ativo;
            this.remanecente = defaultValues.remanecente;
            this.arquivo = defaultValues.arquivo;
            this.data_cadastro = defaultValues.data_cadastro ? new Date(defaultValues.data_cadastro) : undefined;
        }
    }

    public getCategoriaDisplay() {
        return this.categoria || this.categoria === 0 ? CATEGORIA_SERVICO[this.categoria] : undefined;
    }

    public getPrecificacaoDisplay() {
        return this.precificacao || this.precificacao === 0 ? TIPO_PRECIFICACAO[this.precificacao] : undefined;
    }

    public getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'nome',
                label: 'Serviço',
                type: 'text',
                valueDefault: this.nome,
                validators: {
                    required: {
                        value: true,
                        message: 'Informe o nome do serviço'
                    }
                },
                onChange: data => this.nome = data
            },
            {
                name: 'descricao',
                label: 'Descrição',
                type: 'text-area',
                valueDefault: this.descricao,
                validators: {
                    required: {
                        value: true,
                        message: 'Informe a descrição do serviço'
                    }
                },
                onChange: data => this.descricao = data
            },
            {
                name: 'categoria',
                label: 'Categoria',
                type: 'select',
                valueDefault: this.categoria || this.categoria === 0 ? {
                    label: CATEGORIA_SERVICO[this.categoria],
                    value: String(this.categoria)
                } : undefined,
                validators: {
                    required: {
                        value: true,
                        message: 'Informe a categoria do serviço'
                    }
                },
                choices: Object.keys(CATEGORIA_SERVICO).map(x => {
                    return {
                        label: CATEGORIA_SERVICO[Number(x)],
                        value: String(x)
                    }
                }),
                onChange: data => this.categoria = Number(data)
            },
            {
                name: 'precificacao',
                label: 'Precificação',
                type: 'select',
                valueDefault: this.precificacao || this.precificacao === 0 ? {
                    label: TIPO_PRECIFICACAO[this.precificacao],
                    value: String(this.precificacao)
                } : undefined,
                validators: {
                    required: {
                        value: true,
                        message: 'Informe a precificação do serviço'
                    }
                },
                choices: Object.keys(TIPO_PRECIFICACAO).map(x => {
                    return {
                        label: TIPO_PRECIFICACAO[Number(x)],
                        value: String(x)
                    }
                }),
                onChange: data => this.precificacao = Number(data)
            },
            {
                name: 'valor',
                label: 'Valor',
                helpText: 'Informar valor por arcada ou por elemento',
                type: 'moeda',
                valueDefault: this.valor,
                validators: {
                    required: {
                        value: true,
                        message: 'Informe o valor do serviço'
                    }
                },
                onChange: data => this.valor = data
            },
            {
                name: 'prazo',
                label: 'Qual o prazo máximo para a realização desse serviço em dias úteis?',
                type: 'number',
                valueDefault: this.prazo,
                validators: {
                    required: {
                        value: true,
                        message: 'Informe o prazo do serviço'
                    },
                    min: {
                        value: 1,
                        message: 'Informe um valor maior ou igual a 1'
                    }
                },
                onChange: data => this.prazo = data
            },
            {
                name: 'gengiva',
                label: 'Você deseja que o dentista informe a cor da gengiva para este trabalho?',
                type: 'boolean',
                onChange: data => this.gengiva = data,
                valueDefault: !!this.gengiva
            },
            {
                name: 'dente',
                label: 'Você deseja que o dentista informe a cor do dente para este trabalho?',
                type: 'boolean',
                onChange: data => this.dente = data,
                valueDefault: !!this.dente,
            },
            {
                name: 'remanecente',
                label: 'Você deseja que o dentista informe a cor do dente remanescente para este trabalho?',
                type: 'boolean',
                onChange: data => this.remanecente = data,
                valueDefault: !!this.remanecente
            },
            {
                name: 'arquivo',
                label: 'Você deseja que o dentista envie fotos/vídeos para este trabalho?',
                type: 'boolean',
                onChange: data => this.arquivo = data,
                valueDefault: !!this.arquivo,
            }
        ];
    }
}
