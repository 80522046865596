import {withStyles} from '@material-ui/styles';
import {lighten, Theme} from '@material-ui/core';
import Button from '@material-ui/core/Button';

const DangerButton = withStyles((theme: any) => ({
    root: {
        color: theme.palette.dangerBtn.textColor,
        backgroundColor: theme.palette.dangerBtn.bgColor,
        '&:hover': {
            backgroundColor: lighten(theme.palette.dangerBtn.bgColor, 0.2),
        },
    },
}))(Button);

export default DangerButton;
