import {ApiService} from 'services/ApiService';
import {EnderecoLaboratorio} from 'models/EnderecoLaboratorio';
import {ConfiguracaoFrete} from 'models/ConfiguracaoFrete';

export class LaboratorioService {
    private static PERFIL_URL = '/perfil-lab'
    private static ENDERECO_URL = '/endereco-lab'
    private static CONFIG_FRETE_URL = '/configuracao-frete'

    public static async atualizarPerfilLaboratorio(email: string, telefone: string, imagemPerfil?: HTMLCanvasElement) {
        const dataFrom = new FormData();
        dataFrom.set('email', email);
        dataFrom.set('telefone', telefone);

        if (imagemPerfil) {
            dataFrom.set('logo', new File([await new Promise<Blob>((resolve, reject) => {
                imagemPerfil.toBlob(b => {
                    if (b)
                        resolve(b)
                    else
                        reject()
                });
            })], 'logo.png'));
        }
        return await ApiService.put(LaboratorioService.PERFIL_URL, '0', dataFrom)
    }

    public static async obterEnderecoLaboratorio(): Promise<EnderecoLaboratorio> {
        return ApiService.get(LaboratorioService.ENDERECO_URL, '0').then(data => new EnderecoLaboratorio(data))
    }

    public static async atualizarEnderecoLaboratorio(endereco: any): Promise<EnderecoLaboratorio> {
        if (endereco.id) {
            return ApiService.put(LaboratorioService.ENDERECO_URL, String(endereco.id), endereco).then(data => new EnderecoLaboratorio(data))
        } else {
            return ApiService.post(LaboratorioService.ENDERECO_URL, endereco).then(data => new EnderecoLaboratorio(data))
        }

    }

    public static async obterConfiguracaoFreteLaboratorio(): Promise<ConfiguracaoFrete> {
        return ApiService.get(LaboratorioService.CONFIG_FRETE_URL, '0').then(data => new ConfiguracaoFrete(data))
    }


    public static async atualizarConfigFrete(configFrete: any): Promise<ConfiguracaoFrete> {
        if (configFrete.id) {
            return ApiService.put(LaboratorioService.CONFIG_FRETE_URL, String(configFrete.id), configFrete).then(data => new ConfiguracaoFrete(data))
        } else {
            return ApiService.post(LaboratorioService.CONFIG_FRETE_URL, configFrete).then(data => new ConfiguracaoFrete(data))
        }

    }
}
