import React, {useEffect, useState} from 'react';
import {Servico} from 'models/Servico';
import {ServicosService} from 'services/ServicosService';
import {Paginacao} from 'util/Paginacao';
import {Avatar, Card, CardContent, Switch, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {Moeda} from 'components/Moeda';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import StyledTableRow from 'components/StyledTableRow';
import Grid from '@material-ui/core/Grid';
import {RouteComponentProps} from 'react-router';
import {Edit} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import {Loader} from 'components/Loader';
import { ControleDePaginacao } from 'components/ControleDePaginacao';
import {AlertEvent, AlertEventType} from 'app-events';


export function ServicosPage({history}: RouteComponentProps) {
    const [showFormulario, setShowFormulario] = useState(false);
    const [loading, setLoading] = useState(false);
    const [servico, setServico] = useState<Servico>();
    const [servicos, setServicos] = useState<Servico[] | null>();
    const [paginacaoServicos, setPaginacaoServicos] = useState<Paginacao<Servico> | null>();
    const [imageCrop, setImageCrop] = useState<HTMLCanvasElement>()

    useEffect(() => {
        if (paginacaoServicos === undefined) {
            ServicosService.obterListaDeServicos().then(async pag => {
                setPaginacaoServicos(pag);
                setServicos(await pag.getItensProximaPagina());
            }, () => {
                setPaginacaoServicos(null)
            })
        }
    })

    const atualizarServicoNaPagina = (servicoEditado: Servico) => {
        setServicos(u => {
            const servicos1 = [...(u || [])];
            const i = servicos1.findIndex(x => x.id === servicoEditado.id)
            if (i > -1) {
                servicos1[i] = servicoEditado;
            }
            return servicos1;
        })
    }

    const headleSubmitFormlario = (data: any, callbackErro?: any) => {
        if (servico && servico.id) {
            setLoading(true);
            ServicosService.alterarServico(servico, imageCrop).then(servicoEditado => {
                setLoading(false);
                setShowFormulario(false);
                atualizarServicoNaPagina(servicoEditado);
                AlertEvent.emit(AlertEventType.SUCCESS, "Serviço alterado com sucesso!")
            }, (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data)
                }
                setLoading(false);
            })
        } else if (servico) {
            setLoading(true);
            ServicosService.cadatrarNovoServico(servico, imageCrop).then(servicoAdd => {
                setLoading(false);
                setShowFormulario(false);
                setPaginacaoServicos(undefined);
                AlertEvent.emit(AlertEventType.SUCCESS, "Serviço cadastrado com sucesso!")
            }, (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data)

                }
                setLoading(false);
            })
        }
    }


    const toogleAtivacaoServico = async (servicoToogle: Servico) => {
        if (servicoToogle.ativo) {
            ServicosService.bloquearSevico(servicoToogle).then(servicoEditado => {
                atualizarServicoNaPagina(servicoEditado);
                setLoading(false);

                AlertEvent.emit(AlertEventType.SUCCESS, 'Operação realizada com sucesso!')
            }, (err) => {
                setLoading(false);
            })
        } else {
            ServicosService.ativarServico(servicoToogle).then(servicoEditado => {
                atualizarServicoNaPagina(servicoEditado);
                setLoading(false);
                AlertEvent.emit(AlertEventType.SUCCESS, 'Operação realizada com sucesso!')
            }, (err) => {
                setLoading(false);
            })
        }
    }

    return (
        <PageContainer heading="Meus Serviços" breadcrumbs={[]}>
            <GridContainer justify="center"
                           alignItems="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography>
                                <Button variant={'contained'} color={'secondary'} onClick={() => {
                                    history.push("/laboratorio/servico")
                                }}>
                                    Novo Serviço
                                </Button>
                            </Typography>
                            <br/>
                            <Box className="Cmt-table-responsive">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Imagem</TableCell>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>Categoria</TableCell>
                                            <TableCell>Precificação</TableCell>
                                            <TableCell>Valor</TableCell>
                                            <TableCell>Ativo</TableCell>
                                            <TableCell>Editar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {servicos && servicos.length === 0 && <TableRow>
                                            <TableCell colSpan={6}>
                                                Nenhum serviço cadastrado
                                            </TableCell>
                                        </TableRow>}
                                        {servicos && servicos.map(x => {
                                            return (
                                                <StyledTableRow key={x.id}>
                                                    <TableCell className="align-middle">
                                                        <Avatar variant="square"
                                                                                                src={x.imagem}
                                                                                                className="size-50">
                                                        {x.nome && x.nome[0].toUpperCase()}
                                                    </Avatar>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{x.nome}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>
                                                            {x.getCategoriaDisplay() || '-'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>
                                                            {x.getPrecificacaoDisplay() || '-'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="align-middle"><Moeda
                                                        numero={x.valor || 0}/></TableCell>
                                                    <TableCell>
                                                        <Switch
                                                            disabled={loading}
                                                            checked={x.ativo}
                                                            onChange={() => toogleAtivacaoServico(x)}
                                                            color="secondary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton size={"small"} color={'primary'} onClick={() => {
                                                            history.push("/laboratorio/servico/" + String(x.id))
                                                        }}>
                                                            <Edit/>
                                                        </IconButton>
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                <Loader
                                    noShow={paginacaoServicos !== undefined && !!servicos}/>
                            </Box>
                        </CardContent>
                    </Card>
                    {paginacaoServicos &&
                    <ControleDePaginacao totalPaginas={paginacaoServicos.getQuantidadeDePaginas()}
                                         onPageChange={(pg) => {
                                             setServicos(null);
                                             paginacaoServicos.getItensDaPagina(pg).then(ser => {
                                                 setServicos(ser);
                                             })
                                         }}/>}
                </Grid>
            </GridContainer>
        </PageContainer>
    );
}
