import React, {Children, cloneElement, isValidElement, PropsWithChildren, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import {getBackgroundStyle, getOverLayStyle} from '../CmtHelpers/JssHelper';
import {Box} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        '& .Cmt-card-content': {
            position: 'relative',
            zIndex: 1,
        },
    },
    cardOverlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 0,
        width: '100%',
        height: '100%',
    },
}));

const CmtCard = (props: PropsWithChildren<any>) => {
    const {
        backgroundColor,
        backgroundImage,
        gradientDirection,
        className,
        children,
        overlay = {colors: '', opacity: 0, direction: ''},
        ...cardProps
    } = props;
    const backgroundStyles = getBackgroundStyle(backgroundColor, backgroundImage, gradientDirection);
    const overlayStyles = getOverLayStyle(overlay);
    const classes = useStyles();

    const headerRef = useRef<any>(null);

    const handleMouseEnter = () => {
        if (headerRef.current) headerRef.current.onHeaderMouseEntered();
    };

    const handleMouseLeave = () => {
        if (headerRef.current) headerRef.current.onHeaderMouseLeft();
    };

    const childrenWithProps = Children.map(children, (child: any) => {
        // Checking isValidElement is the safe way and avoids a TS error too.
        if (isValidElement(child)) {
            const type: any = child.type
            if (type.render && type.render.name) {
                if (type.render.name === 'CmtCardHeader') {
                    return cloneElement(child, {ref: headerRef} as any);
                }
            } else {
                return cloneElement(child);
            }
        }

        return child;
    });
    const styleBox = {
        style: overlayStyles
    } as any
    return (
        <Card
            className={clsx(classes.root, className)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={backgroundStyles}
            {...cardProps}>
            {childrenWithProps}
            {overlay.colors && <Box className={classes.cardOverlay} {...styleBox}/>}
        </Card>
    );
};

export default CmtCard;
