import React, {useState} from 'react';
import {Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

export function PromocoesPage(props: any) {
    return (
        <PageContainer heading={'Minhas Promoções'} breadcrumbs={[]}>
            <GridContainer justify="center"
                           alignItems="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Button variant={'contained'} color={'secondary'}>
                                Nova Promoção
                            </Button>
                            <Box className="Cmt-table-responsive">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Identifcação</TableCell>
                                            <TableCell>Período</TableCell>
                                            <TableCell>Tipo</TableCell>
                                            <TableCell>Desconto</TableCell>
                                            <TableCell>Ativo</TableCell>
                                            <TableCell>Ação</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                Nenhuma promoção cadastrada
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </GridContainer>
        </PageContainer>
    );
}
