import 'react-perfect-scrollbar/dist/css/styles.css';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';

export const store = configureStore();
// pick a date util library

const MainApp = () =>
    <AppContextProvider>
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <ConnectedRouter history={history}>
                    <AppWrapper>
                        <Routes/>
                    </AppWrapper>
                </ConnectedRouter>
            </MuiPickersUtilsProvider>
        </Provider>
    </AppContextProvider>;

export default MainApp;
