import {
    HIDE_MESSAGE, LAB_CHANGE,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_USER, SIGNIN_USER_SUCCESS,
    SIGNOUT_USER, SIGNOUT_USER_SUCCESS,
} from 'constants/ActionTypes';
import {Usuario} from 'models/Usuario';
import {Laboratorio} from 'models/Laboratorio';

export const userSignIn = (usuario: Usuario, laboratorio: Laboratorio) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: {usuario, laboratorio}
    };
};

export const labChange = (laboratorio: Laboratorio) => {
    return {
        type: LAB_CHANGE,
        payload: laboratorio
    };
};
export const userSignOut = () => {
    return {
        type: SIGNOUT_USER_SUCCESS
    };
};
