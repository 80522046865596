import React, {useEffect, useState} from 'react';
import {IJsonFromField} from 'components/InputFormulario/types';
import {FormHelperText, Switch} from '@material-ui/core';
import {ErrorMessage} from '@hookform/error-message';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {UseFormMethods} from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import InputMask from 'react-input-mask';
import Select2, {OptionType} from 'components/Select2';
import {LocalizacaoInput} from 'components/LocalizacaoInputField';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles<any>(theme => ({
    boxDiv: {
        width: '100%'
    },
    input: {
        width: '100%',
        marginTop: '10px'
    }
}));

export function FieldJsonForm(props: { field: IJsonFromField, formMethods: UseFormMethods<any> }) {
    const classes = useStyles();
    const [valueSelect, setValueSelect] = useState<OptionType[] | OptionType | null>(props.field.valueDefault || null)
    const [valueBoolean, setValueBoolean] = useState<boolean>(!!props.field.valueDefault)
    const [inputRegistrado, setInputRegistrado] = useState(false)
    const {field, formMethods} = props;
    const {errors, register, setValue, watch} = formMethods;
    const disabled = field.disabled && typeof field.disabled === 'function' ? field.disabled() : field.disabled;
    const validadores = field.validators && typeof field.validators === 'function' ? field.validators() : field.validators || {};
    useEffect(() => {
        if (!inputRegistrado && ['select', 'multi-select', 'tel', 'localizacao', 'moeda', 'boolean'].indexOf(props.field.type) > -1) {
            register(field.name, validadores)
            setInputRegistrado(true);
        }
    }, [inputRegistrado])
    const extraComponente = () => {
        return (<>
            {field.helpText ?
                <FormHelperText>{field.helpText}</FormHelperText> : ''}
            <ErrorMessage errors={errors} name={field.name} render={({message, messages}) => {
                if (message) {
                    return <Typography variant='caption' color={'error'}>{message}</Typography>
                }
                if (messages) {
                    return Object.keys(messages).map((x) => <Typography key={x} variant='caption'
                                                                        color={'error'}>{messages[x]}<br/></Typography>)
                }
                return <div/>
            }}/>
        </>)
    };
    const isInvalid = !!errors[field.name];
    if (field.visivel === false) {
        return <></>;
    }
    switch (field.type) {
        case 'hidden':
            return (<input type="hidden" ref={(ref) => register(ref, validadores)}
                           name={field.name}/>);
        case 'datetime-local':
        case 'date':
            return (
                <Box component={'div'} className={classes.boxDiv}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="DD/MM/yyyy"
                        id={field.name}
                        label={field.label}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        onChange={(inputEvent) => {
                            setValue(field.name, inputEvent)
                        }}
                        value={watch(field.name)}
                        defaultValue={props.field.valueDefault}
                        inputRef={(ref) => register(field.name, validadores)}
                        fullWidth
                        margin="normal"
                        className={classes.input}
                        error={isInvalid}
                    />
                    {extraComponente()}
                </Box>
            );
        case 'moeda':
            return (
                <Box component={'div'} className={classes.boxDiv}>
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={'.'}
                        decimalScale={2}
                        allowNegative
                        allowedDecimalSeparators={[',']}
                        decimalSeparator={','}
                        allowEmptyFormatting
                        fixedDecimalScale
                        prefix={'R$ '}
                        type='tel'
                        autoComplete="off"
                        label={field.label}
                        InputProps={{
                            readOnly: field.readonly
                        }}
                        name={field.name}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={Number(field.valueDefault)}
                        fullWidth
                        margin="normal"
                        className={classes.input}
                        error={isInvalid}
                        value={Number(watch(field.name, '0'))}
                        onValueChange={values => {
                            const {floatValue} = values;
                            setValue(field.name, floatValue);
                        }}
                    />
                    {extraComponente()}
                </Box>
            );
        case 'email':
        case 'number':
            return (
                <Box component={'div'} className={classes.boxDiv}>
                    <TextField
                        autoComplete="off"
                        label={field.label}
                        InputProps={{
                            readOnly: field.readonly
                        }}
                        name={field.name}
                        type={field.type}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={field.valueDefault}
                        inputRef={(ref) => register(ref, validadores)}
                        fullWidth
                        margin="normal"
                        className={classes.input}
                        error={isInvalid}
                    />
                    {extraComponente()}
                </Box>
            );
        case 'tel':
            return (
                <Box component={'div'} className={classes.boxDiv}>
                    <InputMask mask="(99) 9999-99999"
                               disabled={!!disabled}
                               name={field.name}
                               defaultValue={field.valueDefault}
                               onChange={event => setValue(props.field.name, event.target.value)}
                               value={watch(props.field.name)}
                    >
                        {(inputProps: any) => <TextField
                            {...inputProps}
                            autoComplete="off"
                            label={field.label}
                            InputProps={{
                                readOnly: field.readonly,
                            }}
                            type={field.type}
                            placeholder={field.placeholder}
                            fullWidth
                            margin="normal"
                            className={classes.input}
                            disableUnderline
                            error={isInvalid}
                        />}
                    </InputMask>
                    {extraComponente()}
                </Box>
            )
        case 'text':
        case 'password':
            return (
                <Box component={'div'} className={classes.boxDiv}>
                    <TextField
                        label={field.label}
                        autoComplete="off"
                        InputProps={{
                            readOnly: field.readonly,
                        }}
                        name={field.name}
                        type={field.type}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={field.valueDefault}
                        inputRef={(ref) => register(ref, validadores)}
                        fullWidth
                        margin="normal"
                        className={classes.input}
                        error={isInvalid}
                    />
                    {extraComponente()}
                </Box>
            );
        case 'text-area':
            return (
                <Box component={'div'} className={classes.boxDiv}>
                    <TextField
                        label={field.label}
                        disabled={!!disabled}
                        name={field.name}
                        placeholder={field.placeholder}
                        autoComplete="off"
                        InputProps={{
                            readOnly: field.readonly,
                        }}
                        error={isInvalid}
                        inputRef={(ref) => register(ref, validadores)}
                        fullWidth
                        margin="normal"
                        className={classes.input}
                    />
                    {extraComponente()}
                </Box>
            );
        case 'select':
        case 'multi-select':
            return (
                <Box component={'div'} className={classes.boxDiv}>
                    <Select2
                        label={field.label}
                        fullWidth
                        className={classes.input}
                        onChange={selected => {
                            setValueSelect(selected);
                            if (Array.isArray(selected)) {
                                setValue(field.name, selected.map(x => x.value));
                            } else {
                                setValue(field.name, selected?.value || null);
                            }
                        }}
                        multiple={field.type === 'multi-select'}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        error={isInvalid}
                        options={props.field.choices ? props.field.choices : []}
                        value={field.type === 'multi-select' && !valueSelect ? [] : valueSelect}/>
                    {extraComponente()}
                </Box>
            );
        case 'localizacao':
            return (<Box component={'div'} className={classes.boxDiv}>
                <InputLabel>{field.label}</InputLabel>
                <LocalizacaoInput value={watch(field.name)} className={classes.input}
                                  defaultLocation={field.valueDefault}
                                  onChange={value => {
                                      setValue(field.name, value);
                                  }}/>
                {extraComponente()}
            </Box>);
        case 'boolean':
            return (<Box component={'div'} className={classes.boxDiv}>
                <GridContainer justify={'flex-start'} alignItems={'center'} style={{
                        paddingTop: 20,
                        paddingBottom: 20
                    }}>
                    <Grid item xs={9} md={10}>
                        <InputLabel>{field.label}</InputLabel>
                    </Grid>
                    <Grid item xs={3} md={2} justify={"flex-end"} alignItems={'flex-end'}>
                        <Switch
                            disabled={disabled}
                            checked={valueBoolean}
                            onChange={(v, checked) => {
                                setValueBoolean(checked);
                                setValue(field.name, checked);
                            }}
                            color="secondary"
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                    </Grid>
                </GridContainer>
                {extraComponente()}
                <hr/>
            </Box>);
        default:
            return <div/>;
    }
}
