import React from 'react';
import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CmtImage from '../../@coremat/CmtImage';
import Button from '@material-ui/core/Button';
import {Box, Card, CardContent, fade} from '@material-ui/core';


export function HomeSitePage(props) {

    return <PageContainer>
        <GridContainer justify={'center'} alignItems={'center'} alignContent={'center'}>
            <Grid item xs={6} style={{
                marginTop: '35vh'
            }}>
                <Card>
                    <CardContent>
                        <Typography align={'center'}>
                            <CmtImage src={'/images/logo-color.png'} alt={'login'}/>
                            <br/>
                            <br/>
                            <Button variant={'contained'} color={'primary'} component={'a'} href={'/#/login'}>
                                LABORATÓRIO
                            </Button>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </GridContainer>
    </PageContainer>
}
