import React, {PropsWithChildren, useRef, useState} from 'react'
import {Cropper} from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Button from '@material-ui/core/Button';
import {ModalCenter} from 'components/ModalCenter';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import DangerButton from 'components/DangerButton';
import Typography from '@material-ui/core/Typography';

let cropper: Cropper | null = null;

export function BtnSelectAndCropImage(props: PropsWithChildren<{
    onSave: (canvas: HTMLCanvasElement) => void
}>) {
    const refInput = useRef<HTMLInputElement>(null);
    const [state, setState] = useState({
        photoFile: undefined as any,
        photoUrl: undefined as any,
        inputValue: undefined as any,
        showPopup: false
    });
    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(s => {
            return {
                ...s,
                photoFile: e.target.files ? e.target.files[0] : undefined
            }
        })
        const file = e.target.files ? e.target.files[0] : undefined
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            setState(s => {
                return {
                    ...s,
                    photoFile: file,
                    photoUrl: fileReader.result,
                    showPopup: true
                }
            })
        }
        if (file) {
            fileReader.readAsDataURL(file)
        }
    }
    const save = () => {
        if (cropper) {
            props.onSave(cropper?.getCroppedCanvas())
            setState(s => {
                return {...s, showPopup: false}
            })
        }
    }
    return (
        <>
            <Button className="btn btn-link" onClick={() => {
                if (refInput && refInput.current && refInput.current.click)
                    refInput.current.click();
            }}>
                {props.children}
            </Button>
            <input type='file' accept="image/jpeg,image/png,image/jpg" value={state.inputValue}
                   style={{display: 'none'}}
                   ref={refInput}
                   onChange={handleFile}/>
            <ModalCenter open={state.showPopup} onClose={() => {
                setState(s => {
                    return {
                        ...s,
                        showPopup: false
                    }
                });
            }}>
                <Cropper
                    src={state.photoUrl}
                    style={{height: 400, width: '100%'}}
                    // Cropper.js options
                    initialAspectRatio={1}
                    aspectRatio={1}
                    guides={false}
                    onInitialized={c => {
                        cropper = c;
                    }}
                />
                <br/>
                <GridContainer>
                    <Grid item xs={6}>
                        <DangerButton onClick={() => setState(s => {
                            return {...s, showPopup: false}
                        })}>
                            Cancelar
                        </DangerButton>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={'right'}>
                            <Button variant='contained' color={'primary'} onClick={save}>
                                Recortar
                            </Button>
                        </Typography>
                    </Grid>
                </GridContainer>
            </ModalCenter>
        </>
    )
}
