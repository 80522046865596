import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {Pedido, STATUS_PEDIDO} from 'models/Pedido';
import {ApiService} from 'services/ApiService';
import {Loader} from 'components/Loader';
import moment from 'moment';
import Numero from 'components/Numero';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Chat from '@material-ui/icons/ChatOutlined';
import {
    Card,
    CardContent,
    CardHeader,
    FormControlLabel,
    Grid,
    Modal,
    Radio,
    RadioGroup,
    Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PedidoStepper from 'components/PedidoStepper';
import Box from '@material-ui/core/Box';
import StyledTableRow from 'components/StyledTableRow';
import {LocalData} from 'components/LocalData';

const useStyles = makeStyles<any>(theme => ({
    cardPedido: {
        height: '100%',
        width: '100%'
    }
}));

export function PedidoPage({match, history}: RouteComponentProps<{ id: string }>) {
    const [pedido, setPedido] = useState<Pedido | null>();
    const classes = useStyles();


    const [breadcrumbs] = useState([
        {label: 'Pedidos', link: '/laboratorio/pedidos'},
        {
            label: `Pedido ${match.params.id.padStart(8, '0')}`,
            link: '/laboratorio/pedido' + match.params.id,
            isActive: true
        }
    ])


    useEffect(() => {
        if (pedido === undefined) {
            ApiService.get('/pedidos', match.params.id).then(ped => {
                setPedido(new Pedido(ped));
            }, () => {
                setPedido(null);
            })
        }
    })

    return (
        <PageContainer heading="" breadcrumbs={breadcrumbs}>
            <GridContainer justify="center"
                           alignItems="center">
                <Grid item xs={12}>
                    <GridContainer>
                        <Grid item md={4} xs={12}>
                            <Card className={classes.cardPedido}>
                                <CardContent>
                                    <Typography variant={'h1'} component={'div'}>
                                        Pedido
                                    </Typography>
                                    <br/>
                                    {!pedido && <Loader/>}
                                    {!!pedido && <Typography>
                                        Número: <b>{match.params.id.padStart(8, '0')}</b><br/>
                                        Status Atual: <b>{pedido.status_display}</b><br/>
                                        Data de
                                        cadastro: <b>{moment(pedido.data_cadastro).format('DD/MM/YYYY')}</b><br/>
                                        Previsão de
                                        entrega: <b>{moment(pedido.data_cadastro).add('days', pedido.prazo || 0).format('DD/MM/YYYY')}</b><br/>
                                    </Typography>}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Card className={classes.cardPedido}>
                                <CardContent>
                                    <Typography variant={'h1'}>Dados do Dentista</Typography>
                                    <br/>
                                    {!pedido && <Loader/>}
                                    {pedido && <Typography>
                                        Nome: <b>{pedido.dentista?.nome}</b><br/>
                                        CRO: <b>{pedido.dentista?.cro}/{pedido.dentista?.estado_cro.sigla}</b><br/>
                                        Endereço: <b>{pedido.endereco_entrega}, {pedido.numero_entrega}{pedido.endereco_complemento ? ' ' + pedido.endereco_complemento : ''}, {pedido.bairro_entrega}</b><br/>
                                        Cidade: <b>{pedido.cidade_entrega?.nome}/{pedido.cidade_entrega?.estado.sigla}</b><br/>
                                        CEP: <b>{pedido.cep_entrega}</b>
                                    </Typography>}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Card className={classes.cardPedido}>
                                <CardContent>
                                    <Typography variant={'h1'}>
                                        Dados do Paciente
                                    </Typography>
                                    <br/>
                                    {!pedido && <Loader/>}
                                    {pedido && <Typography>
                                        Paciente: <b>{pedido.paciente}</b><br/>
                                        Sexo: <b>{pedido.sexo === 0 ? 'Masculino' : 'Feminino'}</b><br/>
                                        Data de
                                        Nascimento: <b>{moment(pedido.data_nascimento).format('DD/MM/YYYY')} ({moment(new Date()).diff(moment(pedido.data_nascimento), 'years')} anos)</b><br/>
                                        {pedido.dente && <>Cor do
                                            dente: <b>{pedido.dente}</b><br/></>}
                                        {pedido.dente_remanecente && <>Cor do dente
                                            remanecente: <b>{pedido.dente_remanecente}</b><br/></>}
                                        {pedido.escala_dente && <>Escala
                                            dente: <b>{pedido.escala_dente}</b><br/></>}
                                        {pedido.gengiva && <>Cor da
                                            gengiva: <b>{pedido.gengiva}</b><br/></>}
                                        {pedido.escala_gengiva && <>Escala da
                                            gengiva: <b>{pedido.escala_gengiva}</b><br/></>}
                                    </Typography>}
                                </CardContent>
                            </Card>
                        </Grid>
                    </GridContainer>
                    <GridContainer>
                        <Grid item xs={12}>
                            {pedido && <PedidoStepper pedido={pedido}/>}
                        </Grid>
                    </GridContainer>
                    <GridContainer>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant={'h1'} component={'div'}>
                                        Serviços a serem realizados
                                    </Typography>
                                    <br/>
                                    <Box className="Cmt-table-responsive">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Item</TableCell>
                                                    <TableCell>Serviço</TableCell>
                                                    <TableCell>Valor</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pedido?.itens?.map((x, i) => {
                                                    return <TableRow hover key={x.id}>
                                                        <TableCell>
                                                            <Typography>{i + 1}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {x.servico_detalhe.nome}<br/>
                                                                {x.elementos && x.elementos.length > 0 && <>Elementos: {x.elementos.join(', ')}</>}
                                                                {(x.arcada || x.arcada === 0) && <>Arcada {x.arcada === 0 ? 'Superior' : 'Inferior'}</>}
                                                                {x.observacao && <>Obs: {x.observacao}</>}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Numero numero={x.valor}
                                                                    minimumFractionDigits={2}
                                                                    mascara={'MOEDA'}/>
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography align="right">
                                                            Total em serviços:
                                                        </Typography>
                                                        <Typography align="right">
                                                            Frete:
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Numero numero={pedido?.valor || 0}
                                                                minimumFractionDigits={2}
                                                                mascara={'MOEDA'}/>
                                                        <Numero numero={pedido?.frete || 0}
                                                                minimumFractionDigits={2}
                                                                mascara={'MOEDA'}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography align="right">
                                                            Total do pedido:
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Numero
                                                            style={{
                                                                fontWeight: 'bold'
                                                            }}
                                                            numero={Number(pedido?.valor || 0) + Number(pedido?.frete || 0)}
                                                            minimumFractionDigits={2}
                                                            mascara={'MOEDA'}/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </GridContainer>
                    <GridContainer>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant={'h1'} component={'div'}>
                                        Movimentação Pedido
                                    </Typography>
                                    <br/>
                                    <Box className="Cmt-table-responsive">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Executante</TableCell>
                                                    <TableCell>Evento</TableCell>
                                                    <TableCell>Observação</TableCell>
                                                    <TableCell>Data</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pedido?.movimentacoes?.map(x => {
                                                    return <TableRow key={x.id}>
                                                        <TableCell>
                                                            <Typography>
                                                                {x.executante}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {STATUS_PEDIDO[x.status_anterior]?.realizado}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {x.observacao || '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            {<LocalData data={x.data_cadastro}/>}
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </GridContainer>
                    <GridContainer>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant={'h1'} component={'div'}>
                                        Arquivos Anexados
                                    </Typography>
                                    <br/>
                                    <Box className="Cmt-table-responsive">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Arquivo</TableCell>
                                                    <TableCell>Data</TableCell>
                                                    <TableCell>Baixar</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pedido?.arquivos?.map(x => {
                                                    const paths = x.filename.split('/')
                                                    return <TableRow key={x.id}>
                                                        <TableCell>
                                                            <Typography>
                                                                <a href={x.arquivo}
                                                                   target="_blank">{paths[paths.length - 1]}</a>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>{<LocalData data={x.data_cadastro}/>}</TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                <a href={x.arquivo} target="_blank">
                                                                    <CloudDownload/>
                                                                </a>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                                {(!pedido?.arquivos || pedido.arquivos.length === 0) && <TableRow>
                                                    <TableCell colSpan={3}>
                                                        Nenhum arquivo foi anexado pelo dentista
                                                    </TableCell>
                                                </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </GridContainer>
                </Grid>
            </GridContainer>
        </PageContainer>);
}
