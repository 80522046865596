import React, {useState} from 'react';
import {Pagination} from '@material-ui/lab';
import Box from '@material-ui/core/Box';

export function ControleDePaginacao<T>(props: { paginaInicial?: number, totalPaginas: number, onPageChange: (pagina: number) => void }) {
    const [pagina, setPagina] = useState(props.paginaInicial || 1);
    const alterarPagina = (novaPagina: number) => {
        setPagina(novaPagina);
        props.onPageChange(novaPagina);
    }
    return (
        <Box component={'div'} style={{
            padding: 20
        }}>
            <Pagination style={{
                margin: "auto"
            }} count={props.totalPaginas} page={pagina} onChange={(e, page) => alterarPagina(page)}/>
        </Box>
    )
}
