import {IJsonFromField} from 'components/InputFormulario/types';

export class ConfiguracaoFrete {
    public id?: number
    public frete_10?: number
    public frete_20?: number
    public intermunicipal?: boolean

    constructor(defaultData?: any) {
        this.id = defaultData?.id
        this.frete_10 = defaultData?.frete_10
        this.frete_20 = defaultData?.frete_20
        this.intermunicipal = defaultData?.intermunicipal
    }

    getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'frete_10',
                label: 'Valor Frete para um raio de até 10km',
                type: 'moeda',
                valueDefault: this.frete_10,
                validators: {
                    min: {
                        value: 0,
                        message: 'Informe um valor maior que zero!'
                    }
                }
            },
            {
                name: 'frete_20',
                label: 'Valor Frete para um raio de até 20km',
                type: 'moeda',
                valueDefault: this.frete_20,
                validators: {
                    min: {
                        value: 0,
                        message: 'Informe um valor maior que zero!'
                    }
                }
            },
            {
                name: 'intermunicipal',
                label: 'Deseja realizar vendas para outros múnicipios em regime de Ecommerce?',
                helpText: 'A precifiação do frete será de acordo com a tabela dos correios com peso e dimenções padrões',
                type: 'boolean',
                valueDefault: !!this.intermunicipal,
            }
        ]
    }
}
