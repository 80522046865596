import {Avatar, Card, CardContent, Grid, Theme} from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import React, {useEffect, useState} from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import {RouteComponentProps} from 'react-router';
import {BtnSelectAndCropImage} from 'components/CropImage';
import {Formulario} from 'components/Formulario';
import {Servico} from 'models/Servico';
import {ServicosService} from 'services/ServicosService';
import {Loader} from 'components/Loader';
import Typography from '@material-ui/core/Typography';
import {createStyles} from '@material-ui/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {AlertEvent, AlertEventType} from 'app-events';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        large: {
            width: theme.spacing(40),
            height: theme.spacing(40),
        },
    }),
);

export function ServicoPage({match, history}: RouteComponentProps<{ id?: string }>) {
    const classes = useStyles();
    const tituloPag = match.params.id ? "Edição Serviço" : "Novo Serviço"
    const [breadcrumbs] = useState([
        {label: 'Serviços', link: '/laboratorio/servicos'},
        {label: tituloPag, link: '/laboratorio/servico/' + (match.params?.id || ''), isActive: true}
    ]);
    const [servico, setServico] = useState<Servico>();
    const [imageCrop, setImageCrop] = useState<HTMLCanvasElement>();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (servico === undefined) {
            if (!match.params?.id) {
                setServico(new Servico());
            } else {
                ServicosService.obterServico(match.params.id).then(s => setServico(s))
            }
        }
    });


    const headleSubmitFormlario = (data: any, callbackErro?: any) => {
        if (servico && servico.id) {
            setLoading(true);
            ServicosService.alterarServico(servico, imageCrop).then(servicoEditado => {
                setLoading(false);
                setServico(servicoEditado);
                AlertEvent.emit(AlertEventType.SUCCESS, "Serviço alterado com sucesso!")
            }, (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data)
                }
                setLoading(false);
            })
        } else if (servico) {
            setLoading(true);
            ServicosService.cadatrarNovoServico(servico, imageCrop).then(servicoAdd => {
                setLoading(false);
                setServico(servicoAdd);
                AlertEvent.emit(AlertEventType.SUCCESS, "Serviço cadastrado com sucesso!")
                AlertEvent.emit(AlertEventType.SUCCESS, 'Serviço cadastrado com sucesso!')
            }, (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data)
                }
                setLoading(false);
            })
        }
    }

    return (
        <PageContainer heading={tituloPag} breadcrumbs={breadcrumbs}>
            <GridContainer justify="center"
                           alignItems="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Loader
                                noShow={servico !== undefined}/>
                            {servico && <>
                                <Typography>Imagem</Typography>
                                <Typography align={'center'}>
                                    <BtnSelectAndCropImage onSave={ic => {
                                        setImageCrop(ic);
                                    }}>
                                        <Avatar variant="square" className={classes.large}
                                                src={imageCrop?.toDataURL() || servico?.imagem}>
                                            Imagem
                                        </Avatar>
                                    </BtnSelectAndCropImage>
                                </Typography>
                                <Formulario name="Perfil" fields={servico.getFieldsFormulario()}
                                            loading={loading}
                                            onSubmit={headleSubmitFormlario} textSubmitButton="Salvar"/>
                            </>}
                        </CardContent>
                    </Card>
                </Grid>
            </GridContainer>
        </PageContainer>
    );
}
