import makeStyles from '@material-ui/core/styles/makeStyles';
import {Box, fade, Typography} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import * as queryString from 'query-string';
import {AuthService} from '../../../../services/AuthService';
import {userSignIn} from '../../../../redux/actions';
import AuthWrapper from './AuthWrapper';
import CmtImage from '../../../../@coremat/CmtImage';
import {useLocation} from 'react-router-dom';
import {Formulario} from '../../../../components/Formulario';

const formulario = [
    {
        name: 'usuario',
        type: 'email',
        label: 'Email',
        validators: {
            required: true
        }
    },
    {
        name: 'senha',
        type: 'password',
        label: 'Senha',
        validators: {
            required: true
        }
    }
];


const useStyles = makeStyles(theme => ({
    authThumb: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
            order: 2,
        },
    },
    authContent: {
        padding: 30,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: (props) => (props.variant === 'default' ? '50%' : '100%'),
            order: 1,
        },
        [theme.breakpoints.up('xl')]: {
            padding: 50,
        },
    },
    titleRoot: {
        marginBottom: 14,
        color: theme.palette.text.primary,
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: fade(theme.palette.common.black, 0.12),
        },
    },
    formcontrolLabelRoot: {
        '& .MuiFormControlLabel-label': {
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
    }
}));

const SignIn = (props) => {

    const usuario = useSelector(({session}) => session.usuario);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {variant = 'default', wrapperVariant = 'default'} = props;
    const classes = useStyles({variant});
    const location = useLocation();

    useEffect(() => {
        const {goBack} = queryString.parse(location.search);
        if (usuario) {
            props.history.push(!goBack ? '/laboratorio' : String(goBack));
        }
    }, [usuario]);


    const realizarLogin = async (data, errorCallback) => {
        setLoading(true);
        AuthService.realizarLogin(data.usuario, data.senha).then(loginRealizado => {
            dispatch(userSignIn(loginRealizado.usuario, loginRealizado.laboratorio))
            setLoading(false);
        }, (e) => {
            setLoading(false);
            if (e.status === 400 && e.data && errorCallback)
                errorCallback(e.data)
        });
    }
    return (
        <AuthWrapper variant={wrapperVariant}>
            {variant === 'default' ? (
                <Box className={classes.authThumb}>
                    <CmtImage src={'/images/logo-color.png'} alt={'login'}/>
                </Box>
            ) : null}
            <Box className={classes.authContent}>
                <Typography component="div" variant="h1" className={classes.titleRoot}>
                    Login
                </Typography>
                <Formulario name={'LoginLav'} fields={formulario} onSubmit={realizarLogin} textSubmitButton={'Entrar'}/>
            </Box>
        </AuthWrapper>
    );
};

export default SignIn;
