import {ApiService} from 'services/ApiService';
import {Cidade} from 'models/Cidade';
import {Estado} from 'models/Estado';
import * as queryString from 'query-string';

export class GeoService {
    private static CIDADES_URL = '/cidades'
    private static ESTADOS_URL = '/estados'

    public static async obterListaDeEstados(): Promise<Array<Estado>> {
        return await ApiService.getAll(GeoService.ESTADOS_URL)
    }

    public static async obterListaDeCidadesPorEstado(estadoId: number, cidadeNome?: string): Promise<Array<Cidade>> {
        return await ApiService.getAll(GeoService.CIDADES_URL, {
            estado_id: estadoId,
            nome__icontains: cidadeNome
        })
    }


    public static async obterCidadePorId(idCidade: number): Promise<Cidade> {
        return await ApiService.get(GeoService.CIDADES_URL, String(idCidade))
    }


    public static async obterEstadoPorId(idEstado: number): Promise<Estado> {
        return await ApiService.get(GeoService.ESTADOS_URL, String(idEstado))
    }

    public static async obterLatLngPorEndereco(data: { endereco?: string, numero?: string, bairro?: string, cidade?: number, estado?: number, cep?: string }): Promise<{ localizacao: { latitude: number, longitude: number } | null }> {
        return ApiService.getAll('/localizacao', data)
    }
}
