import React from 'react'
import Numero from 'components/Numero';

export function Moeda(props: { numero: number } & any) {
    return (
        <Numero
            {...props}
            numero={Number(props.numero)}
            minimumFractionDigits={2}
            mascara={'MOEDA'}
        />
    )
}
