import {
    LAB_CHANGE,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
} from 'constants/ActionTypes';
import {SessionAction, SessionState} from 'redux/reducers/index';

const INIT_STATE: SessionState = {};

export default (state = INIT_STATE, action: SessionAction): SessionState => {
    switch (action.type) {
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case LAB_CHANGE: {
            return {
                ...state,
                laboratorio: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {}
        }
        default:
            return state;
    }
}
