import React from 'react';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useSelector} from 'react-redux';
import {PERMISSOES_USUARIO, Usuario} from '../../../../../models/Usuario';

const useStyles = makeStyles(theme => ({
    perfectScrollbarSidebar: {
        height: '100%',
        transition: 'all 0.3s ease',
        '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
            height: 'calc(100% - 167px)',
        },
        '.Cmt-modernLayout &': {
            height: 'calc(100% - 72px)',
        },
        '.Cmt-miniLayout &': {
            height: 'calc(100% - 91px)',
        },
        '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
            height: 'calc(100% - 167px)',
        },
    },
}));

const SideBar = () => {
    const classes = useStyles();
    const usuario = useSelector(({session}) => session.usuario);
    const navigationMenus = [
        {
            name: 'Início',
            type: 'item',
            link: '/laboratorio/home'
        }]
    if (usuario) {
        const permissoes = (usuario.administrador ? Object.keys(PERMISSOES_USUARIO).map(x => Number(x)) : usuario.permissoes) || [];
        permissoes.forEach(x => {
            switch (x) {
                case 0: {
                    navigationMenus.push({
                        name: 'Pedidos',
                        type: 'item',
                        link: '/laboratorio/pedidos'
                    });
                    break;
                }
                case 1: {
                    navigationMenus.push({
                        name: 'Minha Carteira',
                        type: 'item',
                        link: '/laboratorio/wallet'
                    });
                    break;
                }
                case 2: {
                    navigationMenus.push({
                        name: 'Meus Serviços',
                        type: 'item',
                        link: '/laboratorio/servicos'
                    });
                    break;
                }
                case 3: {
                    navigationMenus.push({
                        name: 'Minhas Promoções',
                        type: 'item',
                        link: '/laboratorio/promocoes'
                    });
                    break;
                }
                case 4: {
                    navigationMenus.push({
                        name: 'Perfil do Laboratório',
                        type: 'item',
                        link: '/laboratorio/perfil'
                    });
                    break;
                }
                case 5: {
                    navigationMenus.push({
                        name: 'Chat',
                        type: 'item',
                        link: '/laboratorio/chat'
                    });
                    break;
                }
                case 6: {
                    navigationMenus.push({
                        name: 'PlugPoits',
                        type: 'item',
                        link: '/laboratorio/plug-points'
                    });
                    break;
                }
            }
        })
        navigationMenus.push({
            name: 'Ajuda',
            type: 'item',
            link: '/laboratorio/ajuda'
        });
    }

    return (
        <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
            <CmtVertical menuItems={navigationMenus}/>
        </PerfectScrollbar>
    );
};

export default SideBar;
