import {IJsonFromField} from 'components/InputFormulario/types';
import {WalletService} from 'services/WalletService';

export class ContaBancaria {
    public id?: number;
    public banco?: number;
    public banco_nome?: string;
    public agencia?: string;
    public dv_agencia?: string;
    public conta?: string;
    public dv_conta?: string;
    public identificacao?: string;
    public data_cadastro?: Date;

    constructor(defaultValue?: any) {
        if (defaultValue) {
            this.id = defaultValue.id;
            this.banco = defaultValue.banco;
            this.banco_nome = defaultValue.banco_nome;
            this.agencia = defaultValue.agencia;
            this.dv_agencia = defaultValue.dv_agencia;
            this.conta = defaultValue.conta;
            this.dv_conta = defaultValue.dv_conta;
            this.identificacao = defaultValue.identificacao;
            this.data_cadastro = defaultValue.data_cadastro !== undefined ? new Date(defaultValue.data_cadastro) : undefined;
        }
    }


    public getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'identificacao',
                label: 'Apelido da Conta',
                type: 'text-area',
                valueDefault: this.identificacao,
                validators: {
                    required: {
                        value: true,
                        message: 'Informe um apelido para a sua conta'
                    }
                },
                onChange: data => this.identificacao = data
            },
            {
                name: 'banco',
                label: 'Banco',
                type: 'select',
                valueDefault: this.banco_nome && (this.banco || this.banco === 0) ? {
                    label: this.banco_nome,
                    value: this.banco
                } : undefined,
                validators: {
                    required: {
                        value: true,
                        message: 'Informe o banco'
                    }
                },
                choices: () => {
                    return WalletService.obterInstituicoesBancarias().then(d => {
                        return d.map(x => {
                            return {
                                label: `${x.codigo} - ${x.nome}`,
                                value: x.id
                            }
                        })
                    })
                },
                onChange: data => this.banco = data
            },
            {
                name: 'agencia',
                label: 'Informe a sua agência',
                type: 'text',
                valueDefault: this.agencia,
                validators: {
                    required: {
                        value: true,
                        message: 'Informe o número da sua agência'
                    },
                    maxLength: {
                        value: 4,
                        message: 'Informe apenas 4 números'
                    },
                    minLength: {
                        value: 4,
                        message: 'Informe os 4 números da sua agência'
                    }
                },
                onChange: data => this.agencia = data
            },
            {
                name: 'dv_agencia',
                label: 'Caso possua, informe o dígito verificador da sua agência',
                helpText: 'Informar 0 no lugar de X.',
                type: 'text',
                valueDefault: this.dv_agencia,
                validators: {
                    maxLength: {
                        value: 1,
                        message: 'Informe apenas 1 número'
                    },
                    minLength: {
                        value: 1,
                        message: 'Informe 1 número para o DV'
                    }
                },
                onChange: data => this.dv_agencia = data
            },
            {
                name: 'conta',
                label: 'Informe o número da sua conta corrente',
                type: 'text',
                valueDefault: this.conta,
                validators: {
                    required: {
                        value: true,
                        message: 'Informe o número da sua conta'
                    },
                    maxLength: {
                        value: 12,
                        message: 'Informe no máximo 12 números'
                    }
                },
                onChange: data => this.conta = data
            }, {
                name: 'dv_conta',
                label: 'Informe o dígito verificador da sua conta',
                type: 'text',
                valueDefault: this.dv_conta,
                validators: {
                    maxLength: {
                        value: 1,
                        message: 'Informe apenas um número'
                    },
                    minLength: {
                        value: 1,
                        message: 'Informe um número para o DV'
                    }
                },
                onChange: data => this.dv_conta = data
            },
        ];
    }
}

