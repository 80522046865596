import {IJsonFromField} from 'components/InputFormulario/types';

export const PERMISSOES_USUARIO: { [key: string]: string } = {
    '0': 'Pedidos',
    '1': 'Carteira',
    '2': 'Serviços',
    '3': 'Perfil do laboratório',
    '4': 'Chat',
    '5': 'Plug Points',
}

export class Usuario {
    public id?: number;
    public ativo?: boolean;
    public celular?: string;
    public email?: string;
    public first_name?: string;
    public last_name?: string;
    public senha?: string;
    public confirmar_senha?: string;
    public administrador?: boolean;
    public permissoes?: Array<number>;

    constructor(defaultValue?: any) {
        if (defaultValue) {
            this.ativo = defaultValue.ativo;
            this.celular = defaultValue.celular;
            this.administrador = defaultValue.administrador;
            this.permissoes = defaultValue.permissoes;
            this.id = defaultValue.id;
            this.first_name = defaultValue.first_name;
            this.last_name = defaultValue.last_name;
            this.email = defaultValue.email;
        }
    }

    public get nome() {
        return `${this.first_name || ''} ${this.last_name || ''}`.trim()
    };

    public getPermissoesDisplay(): string {
        if (this.administrador) {
            return 'Administrador';
        } else if (this.permissoes) {
            return this.permissoes.map(x => PERMISSOES_USUARIO[String(x)]).join(', ');
        } else {
            return 'Nenhuma permissão definida!'
        }
    }

    public getFieldsFormulario(): IJsonFromField[] {
        const formulario: IJsonFromField[] = [
            {
                name: 'first_name',
                label: 'Primeiro Nome',
                type: 'text',
                valueDefault: this.first_name,
                validators: {
                    required: {
                        value: true,
                        message: 'Primeiro Nome é obrigatório'
                    }
                },
                onChange: data => this.first_name = data
            },
            {
                name: 'last_name',
                label: 'Último Nome',
                type: 'text',
                valueDefault: this.last_name,
                onChange: data => this.last_name = data
            },
            {
                name: 'email',
                label: 'Email',
                type: 'email',
                valueDefault: this.email,
                validators: {
                    required: {
                        value: true,
                        message: 'Email é obrigatório'
                    }
                },
                onChange: data => this.email = data
            },
            {
                name: 'celular',
                label: 'Celular',
                type: 'tel',
                valueDefault: this.celular,
                onChange: data => this.celular = data
            },
            {
                name: 'administrador',
                label: 'Usuário é Administrador?',
                type: 'boolean',
                onChange: (data) => this.administrador = !!data,
                valueDefault: !!this.administrador,
            },
            {
                name: 'permissoes',
                label: 'Permissões',
                type: 'multi-select',
                valueDefault: this.permissoes?.map(x => {
                    return {
                        label: PERMISSOES_USUARIO[String(x)],
                        value: String(x)
                    }
                }),
                disabled: () => !!this.administrador,
                validators: () => {
                    return {
                        required: !this.administrador
                    }
                },
                choices: Object.keys(PERMISSOES_USUARIO).map(key => {
                    return {
                        label: PERMISSOES_USUARIO[key],
                        value: key
                    }
                }),
                onChange: data => this.permissoes = data?.map((x: any) => Number(x))
            },
        ]
        if (!this.id) {
            formulario.push(...[
                {
                    name: 'senha',
                    label: 'Senha de acesso',
                    type: 'password',
                    onChange: data => this.senha = data
                },
                {
                    name: 'confirmar_senha',
                    label: 'Confirmar senha',
                    type: 'password',
                    onChange: data => this.confirmar_senha = data
                }
            ] as IJsonFromField[])
        }
        return formulario;
    }
}
