import {Servico} from 'models/Servico';
import {Dentista} from 'models/Dentista';
import {Cidade} from 'models/Cidade';

export const STATUS_PEDIDO: { [key: number]: { andamento: string, realizado: string } } = {
    0: {andamento: 'Aguardando Pagamento', realizado: 'Pagamento Aprovado'},
    1: {andamento: 'Aguardando Laboratório', realizado: 'Laboratório aceitou seu pedido'},
    2: {andamento: 'Aguardando Material', realizado: 'Material recebido'},
    3: {andamento: 'Pedido em Produção', realizado: 'Pedido produzido'},
    7: {andamento: 'Aguardando Entrega', realizado: 'Entrega realizada'},
    8: {andamento: 'Pedido Concluído', realizado: 'Pedido Concluído'},
    9: {andamento: 'Pedido Cancelado', realizado: 'Pedido Cancelado'}
}

export interface ItemPedido {
    id: number,
    servico: number,
    servico_detalhe: Servico,
    arcada: number,
    elementos: string[],
    observacao: string,
    valor: number,
    desconto: number
}

export interface ArquivosPedido {
    id: number,
    arquivo: string,
    filename: string,
    data_cadastro: string
}

export interface MovimentacaoPedido {
    id: number,
    status_anterior: number,
    status_atual: number,
    executante: string,
    observacao: string,
    data_cadastro: string
}

export interface AvaliacaoPedido {
    id: number,
    avaliacao: number,
    depoimento: string
    data_cadastro: string
}

export interface Boleto {
    link: string
    linha_digitavel: string
    vencimento: string
}

export class Pedido {
    public id?: number
    public laboratorio?: number
    public paciente?: string
    public data_nascimento?: string
    public sexo?: number
    public gengiva?: string
    public escala_gengiva?: string
    public dente?: string
    public dente_remanecente?: string
    public escala_dente?: string
    public coleta?: string
    public frete?: number
    public valor?: number
    public juros?: number
    public prazo?: number
    public forma_pagamento?: string
    public parcelas?: string
    public bandeira_cartao?: string
    public numeros_cartao?: string
    public mes_cartao?: string
    public ano_cartao?: string
    public desconto?: number
    public fee_venda?: number
    public endereco_entrega?: string
    public numero_entrega?: string
    public bairro_entrega?: string
    public endereco_complemento?: string
    public cidade_entrega?: Cidade
    public cep_entrega?: string
    public localizacao_entrega?: string
    public motivo_cancelamento?: string
    public status?: number
    public observacao?: string
    public pagamento_liberado?: boolean
    public data_entrega?: string
    public data_cadastro?: string
    public status_display?: string
    public itens?: ItemPedido[]
    public arquivos?: ArquivosPedido[]
    public movimentacoes?: MovimentacaoPedido[]
    public avaliacao?: AvaliacaoPedido
    public dentista?: Dentista

    constructor(defaultValue: any) {
        if (defaultValue) {
            const obj: any = this;
            Object.keys(defaultValue).forEach(x => {
                obj[x] = defaultValue[x];
            })
        }
    }
}
