import Grid from '@material-ui/core/Grid';
import {Card, CardContent, CardHeader, Modal} from '@material-ui/core';
import React, {PropsWithChildren} from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {Close} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';


export function ModalCenter(props: PropsWithChildren<{
    open: boolean,
    onClose: () => void,
    hideBackdrop?: boolean,
    title?: string,
}>) {
    return (
        <Modal open={props.open}
               onClose={props.onClose}
               onBackdropClick={props.onClose}
               style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%", height: "100vh"}}>
            <Card style={{
                width: '80%',
            }}>
                <CardContent>
                    <Box style={{display: 'flex', flexDirection:"row", alignItems: 'center', justifyContent: 'center'}}>
                        <Typography style={{
                            color: "#000"
                        }} variant='h1' component='div'>
                            {props.title}
                        </Typography>
                        <IconButton style={{
                            color: "#000",
                            marginLeft: "auto"
                        }} onClick={props.onClose} color={'primary'}>
                            <Close/>
                        </IconButton>
                    </Box>
                    {props.children}
                </CardContent>
            </Card>
        </Modal>
    );
}
