import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {useSelector} from 'react-redux';
import {HashRouter, useLocation} from 'react-router-dom';
import Error404 from './Pages/404';
import Login from '../routes/Auth/Login';
import RoutesPages from './Pages';
import {HomeSitePage} from './Site/Home';
import {NotificationContainer} from '../components/NotificationContainer';

const Routes = () => {
    const location = useLocation();
    const {usuario} = useSelector(({session}) => session);

    if (usuario && location.pathname === '/login') {
        return <Redirect to={'/laboratorio'}/>;
    }

    return (
        <React.Fragment>
            <NotificationContainer/>
            <Switch>
                <Route path="/login" exact component={Login}/>
                <Route path="/laboratorio" component={RoutesPages}/>
                <Route path={`/home`} exact component={HomeSitePage}/>
                <Route path={`/`} exact component={HomeSitePage}/>
                <Route component={Error404}/>
            </Switch>
        </React.Fragment>
    );
};

export default Routes;
