import React, {PropsWithChildren} from 'react';
import {useForm} from 'react-hook-form';
import {IJsonFromProps} from './types';
import {FieldJsonForm} from 'components/InputFormulario';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const defaultProps: IJsonFromProps = {
    onSubmit: () => {
    },
    textSubmitButton: 'Submit',
    name: '',
    fields: [],
};

export function Formulario(props: PropsWithChildren<IJsonFromProps>) {

    const localProps: IJsonFromProps = Object.assign(defaultProps, props);
    const formMethods = useForm(
        {
            reValidateMode: 'onSubmit',
            defaultValues: localProps.fields.reduce((previousValue, currentValue) => {
                previousValue[currentValue.name] = Array.isArray(currentValue.valueDefault) ? currentValue.valueDefault.map(x => x?.value || x) : currentValue.valueDefault?.value || currentValue.valueDefault
                return previousValue
            }, {} as any)
        }
    );

    localProps.fields.forEach(x => {
        if (x.onChange)
            x.onChange(formMethods.watch(x.name), formMethods.setValue);
    });

    let formElement: HTMLFormElement | null;

    const headleSubmit = (e: any) => {
        formMethods.clearErrors();
        return formMethods.handleSubmit((data: any) => {
            localProps.onSubmit(data, erros => {
                if (erros) {
                    Object.keys(erros).forEach(key => {
                        const erro = erros[key] || '';
                        if (Array.isArray(erro)) {
                            formMethods.setError(key, {
                                types: erro.reduce((pre, pos, i) => {
                                    pre[`erro_${i}`] = pos
                                    return pre
                                }, {} as any)
                            });
                        } else {
                            formMethods.setError(key, {
                                message: erro as string,
                                type: 'required'
                            });
                        }
                    });
                }
            }, formElement);
        })(e)
    };

    return (
        <form autoComplete="off" name={localProps.name} ref={(el) => {
            formElement = el
        }} onSubmit={headleSubmit}>
            <Box mb={2}>
                {props.fields.map(field => {
                    return (<FieldJsonForm formMethods={formMethods} key={field.name} field={field}/>);
                })}
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>

                <Box component="p" fontSize={{xs: 12, sm: 16}}>
                    {props.children}
                </Box>

                <Button type="submit" variant="contained" color="primary" disabled={props.loading}>
                    {props.loading && <CircularProgress color={'inherit'} size={'1rem'}/>}
                    {!props.loading && localProps.textSubmitButton}
                </Button>
            </Box>

        </form>
    );
}
