import React, {useEffect, useState} from 'react';
import {Loader} from 'components/Loader';
import {Wallet} from 'models/Wallet';
import {Moeda} from 'components/Moeda';
import {WalletService} from 'services/WalletService';
import {Paginacao} from 'util/Paginacao';
import {Transacao} from 'models/Transacao';
import {ControleDePaginacao} from 'components/ControleDePaginacao';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs
} from '@material-ui/core';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Formulario} from 'components/Formulario';
import {ContaBancaria} from 'models/ContaBancaria';
import {IJsonFromField} from 'components/InputFormulario/types';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StatisticsCard from '@jumbo/components/Common/StatisticsCard';
import {ModalCenter} from 'components/ModalCenter';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {Add, Edit, DeleteOutline} from '@material-ui/icons';
import StyledTableRow from 'components/StyledTableRow';
import DangerButton from 'components/DangerButton';
import {LocalData} from 'components/LocalData';
import {AlertEvent, AlertEventType} from 'app-events';

const getFormularioSolicitacaoSaque = (saldo: number): IJsonFromField[] => {
    return [
        {
            name: 'conta',
            label: 'Selecione uma conta cadastrada',
            type: 'select',
            validators: {
                required: {
                    value: true,
                    message: 'Informe uma conta cadastrada!'
                }
            },
            choices: () => {
                return WalletService.obterListaDeContasCadastradas().then(data => {
                    return data.map(x => {
                        return {
                            label: `${x.identificacao} (${x.banco_nome}/${x.agencia}/${x.conta}-${x.dv_conta})`,
                            value: x.id
                        }
                    })
                })
            }
        },
        {
            name: 'valor',
            label: 'Valor',
            type: 'moeda',
            validators: {
                required: {
                    value: true,
                    message: 'Informe um valor'
                },
                max: {
                    value: saldo,
                    message: 'Saldo insuficiente'
                }
            }
        }
    ];
}

const useStyles = makeStyles<any>(theme => ({
    cardRoot: {
        color: theme.palette.common.black,
        height: 150,
        width: '100%'
    },
    titleRoot: {
        fontSize: 12,
        fontWeight: 400,
        textTransform: 'uppercase',
        letterSpacing: 0.4,
    }
}));

export function MinhaCarteiraPage(props: any) {
    const classes = useStyles();
    const [loaderWallet, setLoaderWallet] = useState(true);
    const [loaderContaBancaria, setLoaderContaBancaria] = useState(false);
    const [loaderSS, setLoaderSS] = useState(false);
    const [wallet, setWallet] = useState<Wallet>()
    const [paginacaoExtrato, setPaginacaoExtrato] = useState<Paginacao<Transacao> | null>()
    const [extrato, setExtrato] = useState<Transacao[]>()
    const [showFormularioSaque, setShowFormularioSaque] = useState(false)
    const [showFormularioCC, setShowFormularioCC] = useState(false)
    const [contaBancaria, setContaBancaria] = useState<ContaBancaria>();
    const [contas, setContas] = useState<ContaBancaria[] | null>();
    const [tab, setTab] = useState(0)
    const [dropdownContas, setDropdownContas] = useState<{ [key: number]: boolean }>({})

    useState(() => {
        if (wallet === undefined) {
            WalletService.obterWallet().then(w => {
                setWallet(w);
                setLoaderWallet(false);
            }, () => {
                setWallet({
                    disponivel: 0,
                    liberar: 0
                });
                setLoaderWallet(false);
            })
        }
    })

    useEffect(() => {
        if (paginacaoExtrato === undefined) {
            WalletService.obterTransacoes().then(async data => {
                setPaginacaoExtrato(data);
                setExtrato(await data.getItensProximaPagina() || [])
            }, () => {
                setPaginacaoExtrato(null);
                setExtrato([])
            });
        }

    }, [wallet]);

    useEffect(() => {
        if (contas === undefined) {
            WalletService.obterListaDeContasCadastradas().then(cc => {
                setContas(cc);
            }, () => {
                setContas(null);
            })
        }
    }, [extrato])

    const atualizarContaNaPagina = (conta: ContaBancaria) => {
        setContas(cc => {
            const ccs = [...(cc || [])];
            const i = ccs.findIndex(x => x.id === conta.id)
            if (i > -1) {
                ccs[i] = conta;
            }
            return ccs;
        })
    }

    const handleSubmitContaBancaria = (data: any, callbackErro?: any) => {
        if (contaBancaria && contaBancaria.id) {
            setLoaderContaBancaria(true);
            WalletService.editarContaBancaria(contaBancaria).then(contaEditada => {
                setShowFormularioCC(false);
                setLoaderContaBancaria(false);
                atualizarContaNaPagina(contaEditada);
                AlertEvent.emit(AlertEventType.SUCCESS, "Serviço alterado com sucesso!")
            }, (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data)
                }
                setLoaderContaBancaria(false);
            })
        } else if (contaBancaria) {
            setLoaderContaBancaria(true);
            WalletService.cadastrarContaBancaria(contaBancaria).then(servicoAdd => {
                setLoaderContaBancaria(false);
                setShowFormularioCC(false);
                setContas(undefined);
                AlertEvent.emit(AlertEventType.SUCCESS, "Serviço cadastrado com sucesso!")
            }, (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data)
                }
                setLoaderContaBancaria(false);
            })
        }
    }
    const removerContaCorrente = (conta: ContaBancaria) => {
        if (conta.id) {
            WalletService.removerContaBancaria(conta.id).then(() => {
                setContas(undefined);
                AlertEvent.emit(AlertEventType.SUCCESS, "Conta removida com sucesso!");
            });
        }
    }

    const solicitarSaque = async (data: any, callbackErro?: any) => {
        setLoaderSS(true);
        const conta = (await WalletService.obterListaDeContasCadastradas()).find(x => x?.id === data.conta)
        const banco = (await WalletService.obterInstituicoesBancarias()).find(x => x.id === conta?.banco)
        if (conta && banco) {
            WalletService.realizarSolicitacaoDeSaque(data.valor, banco.codigo, String(conta.agencia), `${conta.conta}${conta.dv_conta}`).then(() => {
                setPaginacaoExtrato(undefined);
                setWallet(undefined);
                AlertEvent.emit(AlertEventType.SUCCESS, "Solicitação de transferência realizada com sucesso!")
                setLoaderSS(false);
                setShowFormularioSaque(false);
            }, err => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data)
                }
                setLoaderSS(false);
            });
        }
    }

    return (
        <PageContainer heading="Minha Carteira" breadcrumbs={[]}>
            <GridContainer justify="center"
                           alignItems="center">
                <Grid item xs={12}>
                    <GridContainer justify="center"
                                   alignItems="center">
                        <Grid item xs={12} md={4}>
                            <StatisticsCard
                                description=""
                                className={classes.cardRoot}
                                backgroundColor="#FFFFFF"
                                title="Disponível"
                                titleProps={{
                                    variant: 'inherit',
                                    component: 'h4',
                                    className: classes.titleRoot,
                                }}
                                amount={<>
                                    {loaderWallet && <Loader/>}
                                    {wallet && <Moeda style={{
                                        fontSize: '200%',
                                    }} numero={wallet.disponivel}/>}
                                </>}>
                            </StatisticsCard>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <StatisticsCard
                                description=""
                                className={classes.cardRoot}
                                backgroundColor="#FFFFFF"
                                title="A Liberar"
                                titleProps={{
                                    variant: 'inherit',
                                    component: 'h4',
                                    className: classes.titleRoot,
                                }}
                                amount={<>
                                    {loaderWallet && <Loader/>}
                                    {wallet && <Moeda style={{
                                        fontSize: '200%',
                                    }} numero={wallet.liberar}/>}
                                </>}>
                            </StatisticsCard>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <StatisticsCard
                                description=""
                                className={classes.cardRoot}
                                backgroundColor="#FFFFFF"
                                title="Total"
                                titleProps={{
                                    variant: 'inherit',
                                    component: 'h4',
                                    className: classes.titleRoot,
                                }}
                                amount={<>
                                    {loaderWallet && <Loader/>}
                                    {wallet && <Moeda style={{
                                        fontSize: '200%',
                                    }} numero={Number(wallet.liberar) + Number(wallet.disponivel)}/>}
                                </>}>
                            </StatisticsCard>
                        </Grid>
                    </GridContainer>
                    <br/>
                    <Card>
                        <Tabs
                            value={tab}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="on">
                            <Tab label="Extrato" onClick={() => setTab(0)}/>
                            <Tab label="Dados Bancários" onClick={() => setTab(1)}/>
                        </Tabs>
                        <CardContent>
                            {tab === 0 && <>
                                <Typography>
                                    <Button variant={'contained'} color={'secondary'}
                                            onClick={() => setShowFormularioSaque(true)}>
                                        Nova Tranferência
                                    </Button>
                                    <ModalCenter
                                        title={'Tranferência'}
                                        open={showFormularioSaque}
                                        onClose={() => setShowFormularioSaque(s => !s)}>
                                        {wallet &&
                                        <Formulario name="Perfil"
                                                    fields={getFormularioSolicitacaoSaque(wallet.disponivel)}
                                                    loading={loaderSS}
                                                    onSubmit={solicitarSaque} textSubmitButton="Solicitar"/>}
                                    </ModalCenter>
                                </Typography>
                                <br/>
                                <Box className="Cmt-table-responsive">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Data</TableCell>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell>Valor</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {extrato && extrato.length === 0 && <TableRow>
                                                <TableCell colSpan={3}>
                                                    Nenhuma movimentação
                                                </TableCell>
                                            </TableRow>}
                                            {extrato && extrato.map(x => {
                                                return (
                                                    <TableRow key={x.id}>
                                                        <TableCell>
                                                            <LocalData data={x.data_cadastro}/>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {x.descricao}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Moeda numero={x.valor || 0} aling={'right'} style={{
                                                                color: (x.operacao === 0 ? 'blue' : 'red')
                                                            }}/>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Box>
                                {paginacaoExtrato &&
                                <ControleDePaginacao totalPaginas={paginacaoExtrato.getQuantidadeDePaginas()}
                                                     onPageChange={(pg) => {
                                                         setExtrato([]);
                                                         paginacaoExtrato.getItensDaPagina(pg).then(us => {
                                                             setExtrato(us);
                                                         });
                                                     }}/>}
                            </>}
                            {tab === 1 && <>
                                <Typography>
                                    <Button
                                        variant={'contained'}
                                        size={'small'}
                                        color={'primary'}
                                        onClick={() => {
                                            setContaBancaria(new ContaBancaria());
                                            setShowFormularioCC(true);
                                        }}>
                                        <Add/>
                                    </Button> | <Button
                                    size={'small'}
                                    disabled={!contaBancaria?.id}
                                    variant={'contained'}
                                    color={'default'}
                                    onClick={() => {
                                        setShowFormularioCC(true);
                                    }}>
                                    <Edit/>
                                </Button> | <DangerButton
                                    size={'small'}
                                    disabled={!contaBancaria?.id}
                                    onClick={() => {
                                        if (contaBancaria && window.confirm('Deseja realmente remover essa conta corrente?')) {
                                            removerContaCorrente(contaBancaria);
                                        }
                                    }}>
                                    <DeleteOutline/>
                                </DangerButton>
                                </Typography>
                                <br/>
                                <ModalCenter title={'Conta Corrente'} open={showFormularioCC}
                                             onClose={() => setShowFormularioCC(s => !s)}>
                                    <Box>
                                        {contaBancaria &&
                                        <Formulario name="Perfil"
                                                    fields={contaBancaria.getFieldsFormulario()}
                                                    loading={loaderContaBancaria}
                                                    onSubmit={handleSubmitContaBancaria}
                                                    textSubmitButton="Salvar"/>}
                                    </Box>
                                </ModalCenter>
                                <hr/>
                                <Box className="Cmt-table-responsive">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Identificação</TableCell>
                                                <TableCell>Banco</TableCell>
                                                <TableCell>Agência</TableCell>
                                                <TableCell>Conta</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {contas && contas.length === 0 && <TableRow>
                                                <TableCell colSpan={4}>
                                                    Nenhuma conta corrente cadastrada
                                                </TableCell>
                                            </TableRow>}
                                            {contas && contas.map(cc => {
                                                return (
                                                    <StyledTableRow key={cc.id}
                                                                    selected={contaBancaria?.id === cc.id}
                                                                    onClick={() => {
                                                                        if (contaBancaria?.id === cc.id) {
                                                                            setContaBancaria(undefined);
                                                                        } else {
                                                                            setContaBancaria(cc);
                                                                        }
                                                                    }}>
                                                        <TableCell>{cc.identificacao}</TableCell>
                                                        <TableCell>{cc.banco_nome}</TableCell>
                                                        <TableCell>{cc.agencia}{cc.dv_agencia && `-${cc.dv_agencia}`}</TableCell>
                                                        <TableCell>{cc.conta}-{cc.dv_conta}</TableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </Box>
                                <Loader noShow={!!contas}/>
                            </>}
                        </CardContent>
                    </Card>
                </Grid>
            </GridContainer>
        </PageContainer>
    );
}
